label.tblm-rc {
  cursor: default;
  user-select: none;
  & > label {
    display: flex;
    align-items: center;
  }
  & > input {
    display: none;
    & + i {
      display: inline-block;
      // transform: translateY(-1.5px);
    }
    &[type='radio'] + i {
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.75' y='0.75' width='14.5' height='14.5' rx='7.25' stroke='%23939CA9' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }
    &[type='checkbox'] + i {
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='%23E1E2E4'/%3E%3C/svg%3E%0A");
    }
    &:checked {
      &[type='radio'] + i {
        mask-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7.25' stroke='%23233F73F2' stroke-width='1.5'/%3E%3Ccircle cx='8' cy='8' r='4' fill='%23233F73F2'/%3E%3C/svg%3E%0A");
        background: var(--primary-color);
      }
      &[type='checkbox'] + i {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23122B4B' fill-rule='evenodd' d='M4 0a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm5.756 13.93 5.835-7.468-1.182-.924-5.627 7.203-3.168-4.506-1.228.863L7.75 13.88a1.25 1.25 0 0 0 2.007.05Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        background: var(--primary-color);
      }
    }
    &:disabled {
      &[type='checkbox'] + i {
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='3.5' fill='%23EDEDED' stroke='%23E1E2E4'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

label.tblm-rc.small-checkbox {
  & > input {
    &[type='checkbox'] + i {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      background-size: 100%;
    }
    &:checked {
      &[type='checkbox'] + i {
        background-size: 100%;
      }
    }
  }
}

label.tblm-switch {
  width: 40px;
  height: 20px;
  display: inline-block;
  z-index: 1;
  & > input[type='checkbox'] {
    display: none;
    & + i {
      position: relative;
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      &::before {
        content: '';
        width: 100%;
        height: 16px;
        display: inline-block;
        border-radius: 14px;
        background-color: #b1b6bd;
        transition: 0.5s background-color;

        will-change: background-color;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 10px;
        background-color: $color__lgrey_96;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.08);
        transition: 0.5s all;

        will-change: background-color, left;
      }
    }
    &:checked + i {
      &::before {
        background-color: rgba(var(--primary-color-rgb), 0.3);
      }
      &::after {
        top: 0;
        left: calc(100% - 20px);
        background-color: var(--primary-color);
        transition: 0.5s background-color, 0.5s left;
      }
    }
  }
}

/*
Usage)
!! tblm-rc depend on type
!! tblm-switch only with checkbox

<label class="tblm-rc"><input type="radio" name="radio_example" checked="checked"><i /></label>
<label class="tblm-rc"><input type="checkbox" name="checkbox_example" checked="checked"><i /></label>
<label class="tblm-switch"><input type="checkbox" name="switch_example" checked="checked"><i /></label>
*/
