@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

li.call-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;		

        .call-date-info {
            h3 {
                @include font__subtitle1_sb;
            }

            span {
                @include font__subtitle1;
                display: block;
                padding-top: 8px;
            }
        }

        .call-time-info {
            @include font__small;
            text-align: right;
            color: $color__grey_66;

            b {
                font-weight: 400;
                display: block;
                padding-top: 4px;
            }
        }

    .call-item-call-type {
        border: 0;
        @include font__small_sb;
        background: rgba(var(--accent-color-rgb), 0.1);
        border-radius: 5px;
        padding: 2.5px 6px;
        color: var(--accent-color);
        margin-top: 3px;
    }
}