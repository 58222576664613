@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.table-view-container {
	padding: 0 20px 20px;
	background-color: $color__bg_98;

	.reset-button {
    padding: 11px 8px;
		margin-block: 20px;
		text-align: right;
		color: $color__grey_66;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		text-decoration-line: underline;
	}
	.footer-button {
		width: 100%;
	}
}