@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.call-group-wrapper {
	display: flex;
    flex-direction: column;
    row-gap: 24px;
	padding: 20px;
	height: calc(100% - 56px - 56px); // 100% - header - gnb / app 2 탭 추가될 경우 -48px 필요
	// height: calc(100% - 56px - 56px - 48px);
	overflow-y: auto;
	background-color: $color__bg_98;

	.group-header {
		@include font__body_sm_m;
		padding: 24px 0 16px;
		display: flex;
		justify-content: center;
		color: $color__grey_66;
		&:first-child {
			padding-top: 0;
		}
	}
	.call-list-container {
		padding-block: 4px;
		background-color: $color__white;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
		border-radius: 16px;
		
		.call-item-box {
			display: flex;
			align-items: center;
			margin-inline: 16px;
			padding-inline: 8px;
			min-height: 74px;
			column-gap: 16px;

			&:not(:first-of-type) {
				border-top: 1px solid #f1f1f1;
			}
	
			.call-item-icon {
				margin-right: 16px;
				width: 30px;
				height: 30px;
	
				// call
				&.CALLSTS000 {
					background: url(/assets/images/icons/Call_Default.svg);
				}

				// missing
				&.CALLSTS002, &.CALLSTS004 {
					background: url(/assets/images/icons/Call_Missing.svg);
				}
	
				// outgoing
				&.CALLSTS003 {
					background: url(/assets/images/icons/Call_Outgoing.svg);
				}
				
				// incoming
				&.CALLSTS001 {
					background: url(/assets/images/icons/Call_Incoming.svg);
				}
			}
	
			.call-item-info {
				display: flex;
				flex-direction: column;
				flex: 1;
				
				max-width: calc(100% - 16px - 16px); // .call-item-box의 margin-inline 사이즈만큼 제외
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
				.call-item-phone {
					font-weight: 600;
					font-size: 17px;
					line-height: 24px;
					color: $color__default;
					max-width: 100%;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					&.missing {
						color: $color_error;
					}
				}

				.call-item-call-type {
					@include font__s_small_sb;
					font-style: normal;
					display: block;
					padding: 2px 4px;
					border-radius: 2px;
					background-color: $color__lgrey_96;
					color: $color__dgrey_38;
				}
	
				.call-item-detail-info {
					display: flex;
					align-items: center;
					column-gap: 4px;
				}
				.call-item-nickname {
					@include font__body_sm;
					color: $color__grey_66;
					padding-top: 4px;
					max-width: 100%;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.call-item-time {
				@include font__small;
				color: $color__grey_66;
				text-align: right;
			}
		}
	}
}


.no-result {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	color: $color__dgrey_38;
	height: calc(100% - 56px - 56px); // 100% - header - gnb / app 2 탭 추가될 경우 -48px 필요
	// height: calc(100% - 56px - 56px - 48px);
}

// 통화내역 - filter button in header height 사이즈 고려
.title.middle-align {
	padding-block: 12px !important; // app 1 ver - 56px
}

.call-history-sub-header {
	display: flex;
	align-items: center;
	background-color: $color__white;
	width: 100%;
	justify-content: space-between;

	.filter-button {
		max-width: 60px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--primary-color);
		color: white;
		gap: 4px;
		padding: 8px 9.5px;
		flex: 1;
		min-width: 60px;
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		border-radius: 4px;
		// margin-right: 16px; // app 2
		.icon {
			width: 14px;
			height: 14px;
		}
	}
}


.call-history-filter-wrapper {
	margin-bottom: -20px;
}
.call-history-filter-modal-title {
	margin-bottom: 24px;
}

.call-history-period-wrapper {
	padding-inline: 20px;
	.period-input-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	
		i {
			margin-inline: 8px;
		}
	}
}

.menu-modal-with-icon {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 20px;
		height: 20px;
		display: block;
		margin-right: 4px;
	}

}

.call-history-user-detail-wrapper {
	display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 162px;
	overflow: hidden;
	
	padding-inline: 20px;
	padding-bottom: 20px;

	.user-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 16px;

		.user-text {
			overflow: hidden;
			
			.user-info {
				display: flex;
				align-items: center;
				
				h3 {
					@include font__body_md_sb;
					margin-right: 4px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				span {
					@include font__body_md;
					flex-shrink: 0;
				}
			}
			
			.user-nickname {
				@include font__body_md;
				margin-inline: -20px;
				padding-inline: 20px;
				margin-top: 2px;

				white-space: normal;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}
	
	.user-memo {
		@include font__body_sm;
		height: 100%;
		overflow: auto;
		margin-bottom: 16px;
		margin-top: 8px;
		color: $color__grey_66;
	}

	.detail-hashtag-wrapper {
		ul {
			display: flex;
			align-items: center;
			column-gap: 4px;

			li {
				@include font__s_small;
				padding: 4px 8px;
				border-radius: 1000px;
				&::before {
					content: '#';
				}

				&:first-of-type {
					background-color: $color__lgrey_96;
					color: $color__dgrey_38;
				}

				&:last-of-type {
					background-color: $color__s_coral;
					color: $color__white;
				}
			}
		}
	}

	.call-info-data-list-wrapper {
		margin-top: 20px;

		.call-info-data-list {
			&.multipleList {
				border-bottom: 1px solid #F1F1F1;
			}

			> li {
				padding-block: 16px;
				border-top: 1px solid #F1F1F1;

				&.user-reservation-info {
					&.isBeforeReservation {
						h5, .time-from-now, .reservation-date li {
							color: $color__grey_66 !important;
						}
					}

					h5 {
						@include font__subtitle_sb;
						margin-bottom: 8px;
					}

					.user-reservation-date-wrapper {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.reservation-date-content {
							.time-from-now {
								@include font__subtitle1_sb;
								margin-bottom: 8px;
							}
							
							.reservation-date {
								display: flex;
								align-items: center;

								> li {
									
									&:not(&:first-of-type)::before {
										content: ' | ';
									}
								}
							}
						}
					}
				}
			}
		
		}


		.call-info-sub {
			display: flex;
			align-items: center;
			margin-top: 8px;

			li {
				@include font__body_sm_m;
				&:not(&:first-of-type)::before {
					content: '|';
					display: inline-block;
					color: $color__grey_66;
					margin-inline: 2px;
				}

				&.time, &.duration {
					color: $color__grey_66;
				}

				&.call-count {
					color: $color_error;
				}
			}
		}
		
	}

	// footer CTA
	.footer-sub-wrapper {
		position: static;
		padding: 0;
	}

	.call-detail-separator {
		display: block;
		margin: 20px -20px;
		padding: 0;
		width: 480px;
		height: 1px;
		border: 0;
		background-color: #ededed;
	}

}