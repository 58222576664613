@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.first-time-customer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color__white;

  .image-carousel-container {
    height: calc(100% - 89px);

    .carousel-slider {
      height: 100%;

      .description-wrap {
        padding-top: 56px;
        text-align: left;
        white-space: pre;

        h2 {
          @include font__title_32_sb;
          margin-bottom: 24px;
        }

        p {
          @include font__body_md;
          color: $color__dgrey_38;
        }
      }

      .control-dots {
        position: fixed;
        bottom: 89px; // bottom CTA button height
        padding-top: 8px;
        max-width: 480px;
        width: 100%;
        height: 24px;
        margin: 0;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
          background-color: $color__lgrey_89;
          box-shadow: none;
          opacity: 1;
          margin-inline: 4px;

          &.selected {
            background-color: var(--primary-color);
          }
        }
      }

      .slider-wrapper li.slide {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        height: calc(100vh - 89px - 24px - 16px);
        padding-inline: 20px;
        justify-content: space-evenly;

          .image-wrap {
            padding-top: 40px;
            overflow: hidden;
            display: flex;
            
            > img {
              height: 100%;
              width: auto;
              max-height: 360px;
              margin: 0 auto;
            }
          }
      }
    }
  }

  .button-container {
    // CTA
    // position: fixed;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 480px;
    padding: 20px;
    border-top: 1px solid $color__lgrey_93;

    .footer {
      width: 100%;
    }
  }
}
