@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.picker-body {
	margin-bottom: 20px;

	.picker-title {
		font-weight: 600;
		font-size: 17px;
		line-height: 20px;
		color: $color__black;
		margin-left: 20px;
		margin-bottom: 24px;
	}

	.picker-container {
		height: 164px;
		position: relative;
		.selected-background {
			position: absolute;
			background-color: $color__lgrey_89;
			top: calc(50% - 20px);
			left: 0;
			width: 100%;
			height: 40px;
			z-index: 1;
		}

		.picker-roller-container {
			display: flex;
			justify-content: center;
			height: 100%;
			width: 100%;

			.picker-roller {
				overflow-y: scroll;
				height: 100%;
				scroll-snap-type: y mandatory;
				scroll-behavior: smooth;
				z-index: 2;

				&.year-roller {
					margin-right: 16px;
				}
			}

			.picker-item {
				color: $color__grey_75;
				font-weight: 600;
				font-size: 15px;
				line-height: 18px;
				text-align: center;
				margin-bottom: 16px;
				scroll-snap-align: center;
				overflow: hidden;
				height: 18px;
				text-overflow: ellipsis;
				white-space: nowrap;
				&.selected {
					font-weight: 600;
					font-size: 20px;
					line-height: 28px;
					height: 28px;
					color: var(--primary-color);
				}
			}

			.picker-item-placeholder {
				height: 34px;
				width: 100%;
				scroll-snap-align: center;
			}
		}

	}
}

.picker-footer {
	padding: 20px;
	border-top: 1px solid $color__lgrey_93;
	.set-button {
		width: 100%;
	}
}