@import '../../utils/variables';
@import '../../utils/boxshadow';
@import '../../utils/filters';
@import '../../utils/mixin';
@import '../../utils/responsive';
@import '../../utils/textstyle';

@import '../../presets/buttons';

.tutorial-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 56px 0px 0px;
  background-color: $color__white;
  position: relative;
  & > .tutorial-content-container {
    // 여기 padding-inline 넣지 않기
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: calc(100% - 89px);
    //margin-bottom: 109px;
    & > .tutorial-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;

      & .tutorial-subquestion-head,
      & .tutorial-subquestion-body {
        padding-inline: 20px;
      }
      & > .tutorial-question {
        @include font__title_32;
        padding-inline: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        white-space: pre-line;
      }
      & > .tutorial-note {
        @include font__body_md;
        margin-bottom: 32px;
        white-space: pre-line;
        padding-inline: 20px;
        &.before {
          margin-bottom: 24px;
        }
      }
      & > .naverkakao {
        height: 100%;
      }

      & > .tutorial-interaction {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        //padding-bottom: 20px;
        //overflow: auto;
        & .row {
          padding-inline: 20px;
        }
        .facilities-list {
          overflow: auto;
        }
        .wrapper {
          padding-inline: 20px;
          padding-bottom: 20px;
          gap: 24px 16px;
          height: 100%;

          & label.facilitiesCheck i {
            padding: 7px 16px;
          }
        }
        &.select {
          margin-top: auto;
        }
        &.button {
          padding-inline: 20px;
          flex-direction: column;
          gap: 16px;
          & > button {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @include font__subtitle1_sb;
            @include tblm-button-base;
            background-color: $color__lgrey_96;
            &.active {
              position: relative;
              background-color: $color__white;
              color: var(--primary-color);
              box-shadow: 0 0 0 1px inset var(--primary-color);
            }
          }
        }
        > button:active {
          color: var(--primary-color);
          background-color: #ffffff;
          border: var(--primary-color) solid 1px;
        }
        &.checkbox {
          //padding-bottom: 20px;
          flex-grow: 0;
          overflow: overlay;
          overflow: auto;
        }
        &.until {
          flex-direction: column;
          // justify-content: center;
          margin-top: 64px;
          row-gap: 16px;
          height: -webkit-fill-available;
          height: 100%;
          @include font__title_20;
          & > div.row {
            display: flex;
            align-items: center;
            & > div.selectbox {
              width: 232px;
              height: 40px;
              border-radius: var(--textfield-radius);
              border: 1px solid $color__lgrey_89;
              margin-right: 12px;
              padding: 9px 12px;
              @include font__body_sm_m;
              &.select-placeholder {
                color: $color__grey_75;
              }
              & > svg {
                float: right;
                height: 100%;
                // width: 20px;
                // height: 20px;
              }
            }
          }
        }
      }
    }
    &.essential-tutorial-content-container {
      .tutorial-note:empty {
        display: none;
      }
      .tutorial-interaction {
        // height: calc(100% - 151px);
        height: 100%;
        overflow: auto;
      }
    }
  }
  & > .button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    // margin: 20px -20px 0 -20px;
    width: 100%;
    max-width: 480px;
    background-color: $color__white;
    padding: 20px;
    border-top: 1px solid $color__lgrey_89;
    z-index: 7;
    & > button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      @include font__subtitle1_sb;
      &.prev {
        @include tblm-button-gray;
      }
      &.next {
        @include tblm-button-color(var(--primary-color-rgb));
        color: $color__white !important;
        padding: 0;
      }
    }
  }
  &.unfixed {
    padding-bottom: 0;
    & > .tutorial-content-container {
      height: 100%;
      & > .tutorial-content {
        display: flex;
        height: 100%;
        overflow: overlay;
        overflow: auto;
      }
    }
  }
  &.fulled {
    height: auto;
    padding-bottom: 0;
    & > .tutorial-content-container {
      height: auto;
      & > .tutorial-content {
        display: flex;
      }
    }
    & > .button-container {
      position: sticky;
      //bottom: unset;
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 20px auto 0;
      bottom: 0;
    }
  }
  &.nobutton {
    & > .tutorial-content-container {
      //height: 100%;
    }
  }
}

.reservation-nono {
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $color__dgrey_38;
  text-decoration: underline;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding-bottom: 13px;
}
