@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.menuinformation-select-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid $color__lgrey_93;
  border-bottom: 1px solid $color__lgrey_93;
  max-width: 100% !important;

  .body-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }
  .no-menu-list {
    font-weight: 500;
    font-size: 15px;
    color: $color__grey_75;
  }
  .con {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
    padding-top: 20px;
    max-height: 100%;
    overflow-y: auto;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      background: $color__default;
      border-radius: 100px;
      color: $color__white;
      padding: 8px 16px;
      div {
        margin-right: 4px;
      }
      span {
        color: var(--accent-color);
        margin-right: 4px;
      }
      svg {
        cursor: pointer;
      }
    }
    &.isModal {
      max-height: 145px;
    }
  }
}
