@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

// @include font__subtitle1_sb;

.user-input-list-container {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    .user-add-info-container {
        h3 {
            @include font__subtitle1_sb;
            margin-bottom: 8px;
        }

        .user-add-info {

            .input-wrap {
                display: flex;
                column-gap: 8px;
                align-items: baseline;
                padding-block: 12px;
                border-bottom: 1px solid $color__lgrey_96;
                
                label {
                    @include font__body_sm;
                    min-width: 104px;
                }

                .custom-input-wrapper {
                    
                }
            }
        }
    }
}