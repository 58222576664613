@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.apply_consultation_container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .apply_consultation__content {
        max-height: calc(100% - 121px - 55px); // 121px: CTA button, 55px: header
        flex: 1;
        overflow: auto;

        .info_section {
            background-color: $color__white;
            padding-top: 32px;

            .tm_logo {
                width: 216px;
                height: auto;
                text-align: center;
                margin: 0 auto;
                display: block;
            }
        
            .info_desc__list {
                margin: 0 auto;
                padding-block: 32px;
        
                li {
                    text-align: center;
                    color: $color__dgrey_38;
                    
                    &:not(&:last-of-type) {
                        margin-bottom: 4px;
                    }
        
                    > span {
                        @include font__body_sm_sb;
                        color: var(--primary-color);
                        margin-right: 4px;
                    }
                }
            }
        }
        
        .form_container {
            background-color: $color__white;
            margin: 16px 20px;
            padding: 20px 16px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
        
            .form_container__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 8px;
        
                h3 {
                    @include font__body_sm_sb;
                    color: $color__default;
                }
        
                span {
                    @include font__small;
                    color: $color__s_coral;
                    display: block;
        
                    &::before {
                        content: '*';
                        display: inline-block;
                    }
                }
            }
        
            .form_container__input_wrap {
                .input_wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-block: 12px;
        
                    
                    .required {
                        &::after {
                            content: '*';
                            display: inline-block;
                            color: #F02B28;
                            margin-left: 6px;
                        }
                    }
        
                    label {
                        min-width: 112px;
                        color: $color__default;
                    }
        
                    
                }
            }
        }
    }
}


.apply_button_container {
    position: sticky;
    bottom: 0;
    max-width: 480px;
    width: 100%;
    background-color: $color__white;
    border-top: 1px solid $color__lgrey_93;
    padding: 20px;

    .agreement_privacy_button {
        @include font__small_m;
        text-decoration: underline;
        color: $color__dgrey_38;
        padding-bottom: 16px;
        display: block;
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;
    }

    .apply_button {
        width: 100%;
    }
}