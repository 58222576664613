@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialCheckbox styles
.tutorial-checkbox {
  width: calc(100% + 40px);
  border-radius: 20px;
  padding-inline: 20px;

  & > ul {
    color: $color__black;
    overflow: overlay;
    overflow: auto;
    & > li {
      border-top: 1px solid $color__lgrey_93;
      &:first-child {
        border-top: 0;
      }
      & > label {
        display: flex;
        padding: 16px 4px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        cursor: pointer;
        & > i {
          margin-left: auto;
        }
      }
    }
  }
}
