@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.custom-input-wrapper {
  display: flex;
  width: 100%;
  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 12px;

    width: 100%;
    height: 40px;

    border: 1px solid $color__lgrey_89;
    border-radius: var(--textfield-radius);

    color: $color__default;
    background: $color__white;
    outline: 0;
    &:focus,
    &:active {
      border-width: 2px;
      box-sizing: border-box;
      padding: 8px 11px;
    }
    &:disabled {
      background: $color__lgrey_96;
      border: 1px solid $color__lgrey_89;
      color: $color__grey_75;
    }
    &::placeholder {
      color: $color__grey_75;
    }
    &[error] {
      border: 1px solid $color_error;
      & + span {
        color: $color_error;
        padding: 0 12px;
      }
      &:focus,
      &:active {
        border-width: 2px;
        box-sizing: border-box;
      }
    }
  }
}

textarea {
  resize: none;
  border: 1px solid $color__lgrey_89;
  border-radius: var(--textfield-radius);
  outline: 0;

  &:focus,
  &:active {
    border-width: 2px;
    box-sizing: border-box;
  }
}
