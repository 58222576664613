@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.custom-select-wrapper {
  /* Auto layout */
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  width: 100%;
  height: 40px;

  background: $color__white;

  border: 1px solid $color__lgrey_89;
  border-radius: var(--textfield-radius);

  &.disabled {
    background: $color__lgrey_96;
    border: 1px solid $color__lgrey_89;
    color: $color__grey_75;
    & > input {
      background: inherit;
    }
  }
  & > input {
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: $color__grey_75;
    font-size: 15px;
    &.isSelected {
      color: $color__default;
    }
  }

  & svg {
    min-width: 16px;
  }
}
