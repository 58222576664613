@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.navigation__container {
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    padding-block: 5px;
    background: $color__white;
    filter: drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.05));

    ul.navigation__list {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            cursor: pointer;

            
            .nav__icon {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            > div {
                @include font__s_small_m;
                color: $color__grey_75;
            }
        }
        .active_btn {
            .nav__icon {
                svg > path {
                    fill: $color__default;
                }
            }

            > div {
                color: $color__default;
            }
        }
    }
}