.main-user-search {
  width: 100%;
  height: 100%;
  background-color: $color__white;

  .search-user-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    gap: 16px;
    & > .custom-search-input-wrapper {
      input {
        height: 48px;
      }
    }
  }
}