@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.food-list-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-end;

  .food-list {
    //position: absolute;
    //right: 0;
    //bottom: 0;
    //left: 0;
    margin: 19px auto 0;
    width: 100%;
    // max-width: 375px;
    height: auto;
    //max-height: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    background: $color__white;
    filter: drop-shadow(0px -8px 16px rgba(0, 0, 0, 0.08));
  }
}
