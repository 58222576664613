@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.hide-deposit-content {
    display: none !important;
}

.deposit-setting-content {
    .custom-check-wrapper {
        padding: 0;
    }
    .detail-body {
        padding-top: 8px;
    }
}