@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.depositsetting-wrapper {
  margin: 16px 20px 0;
  padding: 16px 20px;
  background: $color__white;
  overflow: auto;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 4%);
  border-radius: 8px;
  .title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
  .depositsetting-content {
    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 17px 0;
      &:not(&:first-of-type) {
        border-top: 1px solid $color__lgrey_96;
      }
      > div:nth-child(1) {
        min-width: 104px;
        line-height: 22px;
      }
      > div:nth-child(2) {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  .payment-status-wrap {
    .payment-status-values {
      width: 100%;
    }
  }
}

// modal
.deposit-setting-modal-content {
  .payment-info-desc {
    @include font__small;
    color: $color__dgrey_38;
    margin-top: 16px;
  }

  .custom-select-wrapper {
    margin-block: 16px;
  }

  .refund-info-desc {
    @include font__body_sm;
    margin-top: 5px;
  }
}