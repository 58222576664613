.month-calendar-wrapper {
  background: $color__white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &::before {
    position: absolute;
    content: '';
    top: -1px;
    width: 100%;
    height: 2px;
    background: $color__white;
  }
}
.timepicker-wrapper {
  background: $color__white;
  padding: 0 20px;
}

.table-setting-wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  & > nav.is-fixed {
    & + .setting-area {
     // height: auto;
    }
  }
  & > .setting-area {
    // height: calc(100% - 89px - 136px);
  }
}
.select-persons-wrapper {
  background: $color__white;
  padding-bottom: 32px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .font__subtitle1_sb {
    padding: 0 20px;
  }

  .slide-x {
    padding: 0 20px;
  }
  .tblm-chip-filter-num {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.select-table-wrapper {
  background: $color__white;
  & > .table-title {
    padding: 32px 20px 8px 20px;
  }
  & > .table-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 16px;
  }
}
.user-search-wrapper {
  width: 100%;
  min-height: calc(100% - 136px - 89px);
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  background: $color__white;
  gap: 24px;
  button.search-button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .logs-data-container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-block: 24px;
    border-top: 1px solid $color__lgrey_93;

    .calllog-container {
      // 통화 기록
      .callog-title {
        @include font__subtitle1_sb;
        margin-bottom: 16px;
      }

      .calllog-wrapper {
        > ul {
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block: 10px;
            min-height: 48px;
            
            .calllog-user-info {
              display: flex;
              align-items: center;
              column-gap: 8px;

              p {
                @include font__body_sm;
              }

              svg.call-icon {
                width: 24px;
                height: 24px;
              }
            }

            .calllog-date-info {
              @include font__small;
              color: $color__grey_66;
            }
          }
        }
      }
    }

    .scrollable-user-list {
      // 검색 결과
      h4 {
        @include font__subtitle1_sb;
        margin-bottom: 16px;
      }

      .user-list-wrapper {
        li {
          padding: 12px 4px;
          margin-bottom: 8px;
        }
      }

      .view-more-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 4px;
        min-height: 48px;
        border: none;
        background-color: $color__white;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
.parties-wrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  & > span {
    &::after {
      content: ', ';
      padding-right: 5px;
    }
    &:last-child {
      &::after {
        content: '';
      }
    }
  }
}
.reservation-detail-wrapper {
  padding: 32px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $color__bg_98;

  & .guide-message {
    margin-left: 4px;
    line-height: 16px;
    font-size: 13px;
    color: $color__s_coral;
  }

  & > .detail-content {
    padding: 20px 16px 16px 15px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    background: $color__white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    .custom-check-wrapper {
      padding: 0;
    }

    & > .detail-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 0;

        & > div:first-child {
          max-width: 104px;
          width: 100%;
          word-break: keep-all;
          &.required {
            &::after {
              content: '*';
              line-height: 30px;
              padding-left: 6px;
              color: $color_error;
            }
          }
        }
        & > div:last-child {
          width: 100%;
        }
      }
    }
  }

  & .menuinfomation-content {
    padding-bottom: 20px;
    gap: 20px;
  }
}

.accept-step-4-container {
  height: 100%;
}