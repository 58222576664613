@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.customer-detail-call-history-container {
    padding: 24px 20px;

    .call-log-itemp-wrapper {
        padding-block: 14px;
        border-bottom: 1px solid $color__lgrey_96;
    }
}