@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.access_agreement_wrap {
  padding-top: 4px;
  background-color: $color__bg_98;

  .access_agreement {
    max-width: 284px;
    margin-inline: auto;
    padding-bottom: 40px;

    .access_agreement___list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 8px;
      @include font__s_small_m;
      color: $color__grey_75;

      & > article {
        display: flex;
        align-items: center;
        height: 14px;

        &:not(&:first-of-type, &:last-of-type)::before {
          content: '';
          width: 1px;
          height: calc(100% - 2px);
          margin-inline: 7px;
          background: $color__grey_75;
        }
      }
    }

    .logo {
      width: 100%;
      margin-top: 20px;
    }
  }
}