@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.reservation-detail-body {
  .detail-content {
    margin: 16px 20px 0;
    padding: 20px 16px 16px 15px;
    display: flex;
    flex-direction: column;
    background: $color__white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    & > .detail-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        width: 100%;

        & > div:first-child {
          &.required {
            &::after {
              content: '*';
              line-height: 30px;
              padding-left: 6px;
              color: $color_error;
            }
          }
        }
        & > div:first-child {
          min-width: 104px;
        }
        & > div:last-child {
          // max-width: 191px;
          width: 100%;
        }
      }
    }
  }
}
