@import 'utils/variables';
@import 'utils/boxshadow';
@import 'utils/filters';
@import 'utils/mixin';
@import 'utils/responsive';
@import 'utils/textstyle';

@import 'presets/buttons';

// Presets
@import './presets/grid';
@import './presets/flex';
@import './presets/animations';
@import './presets/font';
@import './presets/buttons';

// base
@import './base/reset';
@import './base/common';
@import './base/typograpy';

// components
@import './components/buttons';
@import './components/chipandtag';
@import './components/selection';
@import './components/modal';

// components-hook-from
@import './components/hook-form/ControlledRadioBox';
@import './components/hook-form/ControlledCheckBox';

// lib

// layout

// pages
@import './pages/list';

// reservation
@import './pages/reservation/accept/accept';
@import './pages/reservation/user-search/user-search';

// settings
@import './pages/setting/policy';
@import './pages/setting/schedule-management';

#root {
  display: flex;
  justify-content: center;
  background-color: $color__bg_98;
  -webkit-tap-highlight-color: transparent !important;
  width: 100%;
  height: 100%;
  //-webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}
.main-container-desktop {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  max-width: $max-width;
  position: relative;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
}
