/* Presets */
button.tblm-button-normal {
  @include tblm-button-normal;
}
button.tblm-button-small {
  @include tblm-button-small;
  @include font__small_m;
}
button.tblm-btn-primary {
  @include font__subtitle1_sb;
  @include tblm-button-color(var(--primary-color-rgb));
  color: $color__white !important;
}
button.tblm-btn-float {
  @include tblm-button-floating;
}
button.tblm-btn-gray {
  @include font__subtitle1_sb;
  @include tblm-button-gray;
}
button.tblm-btn-outlined {
  @include tblm-button-outlined(var(--primary-color-rgb));
}
button.tblm-btn-paleblue {
  @include font__subtitle1_sb;
  @include tblm-button-color(var(--primary-5-rgb), var(--primary-color-rgb));
  color: var(--primary-color) !important;
}

/* <예약 받는 시간> 버튼 style */
.reservation-time-tag-wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > .reservation-time-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 20px;
    // width: 23%;
    width: calc(25% - 6px);
    background: $color__white;
    border: 1px solid $color__lgrey_93;
    color: $color__black;
    border-radius: var(--textfield-radius);
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}
