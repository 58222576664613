.modal-wrapper {
  z-index: 8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  & > .modal-dim {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: $color__default;
  }
  &.alert-error > .modal-dim {
    background-color: $color__white;
    opacity: 1;
  }
  & > .modal-content {
    & > .title {
      position: relative;
      padding: 0 20px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
    }
    & > .close {
      padding: 12px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    & > .footer {
      padding: 10px 20px;
      width: 100%;
      height: auto;
      display: flex;
      gap: 20px;

      &.is-border {
        border-top: 1px solid $color__lgrey_93;
      }
      & > button {
        width: 100%;
      }
    }
    & > .footer-sub-wrapper {
      max-width: $max-width;
    }
  }

  &.alert {
    &.alert-error .close {
      visibility: hidden;
    }

    &.animation {
      animation-name: fadeIn;
      animation-duration: 0.3s;
    }
    & > .modal-content {
      @include positionCenter(absolute);
      padding: 24px;
      width: calc(100% - 40px);
      max-width: $max-width - 60;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 2px;
      background: $color__white;
      box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);

      & > .close,
      & > .title,
      & > .footer {
        padding: 0;
        &.success {
          padding: 8px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          color: var(--primary-color);
          cursor: pointer;
        }
      }
    }
  }
  &.normal {
    &.animation {
      animation-duration: 0.3s;
      animation-name: fadeIn;
    }
    & > .modal-content {
      @include positionCenter(absolute);
      max-width: $max-width;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      background: $color__white;
      // padding: 24px;
      height: 100%;
      box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
      border-radius: 2px;

      & > .close,
      & > .title,
      & > .footer {
        padding: 0;
        &.success {
          display: flex;
          justify-content: flex-end;
          color: var(--primary-color);
          width: 100%;
          padding: 8px;
          cursor: pointer;
        }
      }
    }
  }
  &.menu {
    & > .modal-content {
      &.animation {
        animation-name: slideUp;
        animation-duration: 0.3s;
      }

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      width: 100%;
      max-width: $max-width;
      height: auto;
      overflow: auto;
      max-height: calc(100% - 44px);
      display: flex;
      flex-direction: column;
      border-radius: 20px 20px 0 0;
      background: $color__white;
      box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.08);

      &.reservation_list_only {
        max-height: calc(100% - 56px - 67px); /** max-height: calc(100% - 56px(예약 현황 header height) - 67px(n월 달력 height)); 퍼센트 계산 */
      }
      
      & > .title {
        height: 44px;
        
        &.no-size {
          height: auto;
        }
      }
      & > .close {
        min-height: 52px;
      }
      & > .content {
        padding: 0 20px;
        padding-bottom: 20px;
        width: 100%;
        display: block;
        overflow-y: auto;
        & > label {
          display: flex;
          padding: 16px 4px;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          border-bottom: 1px solid $color__lgrey_93;
          cursor: pointer;
        }
      }
    }
  }
  &.toast {
    &.animation {
      animation-name: fadeIn;
      animation-duration: 0.5s;
    }
    & > .modal-content {
      @include positionCenter(absolute);
      padding: 16px 12px;
      width: calc(100% - 40px);
      max-width: $max-width - 40;
      height: auto;
      border-radius: var(--textfield-radius);
      background: $color__default;
      & > .content {
        color: $color__white;
      }
      &.top {
        top: calc(0% + 40px);
      }
      &.bottom {
        top: calc(100% - 40px);
      }
    }
  }
  &.setting {
    &.animation {
      animation-name: slideUp;
      animation-duration: 0.3s;
    }
    & > .modal-content {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      padding: 52px 20px 20px 20px;
      width: 100%;
      max-width: $max-width;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 20px 20px 0 0;
      background: $color__white;
      & > hr {
        height: 1px;
        width: calc(100% + 40px);
        margin: 20px -20px;
        border: 0;
        background-color: $color__lgrey_93;
      }
      & > div.content-wrapper {
        @include font__body_md;
      }
      & > div.button-wrapper {
        display: flex;
        gap: 8px;
        & > button {
          flex: 0.5;
          @include tblm-button-normal;
        }
      }
      &.continue {
        & > div.content-wrapper {
          @include font__body_md;
          & > .emphasis {
            @include font__title_32_sb;
            margin-top: 24px;
            margin-bottom: 28px;
            margin-right: 124px;
            word-break: keep-all;
          }
        }
      }
    }
  }
  &.check {
    &.animation {
      animation-name: slideUp;
      animation-duration: 0.3s;
    }
    & > .modal-content {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      padding: 52px 20px 20px 20px;
      width: 100%;
      max-width: $max-width;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 20px 20px 0 0;
      background: $color__white;
      & > hr {
        height: 1px;
        width: calc(100% + 40px);
        margin: 20px -20px;
        border: 0;
        background-color: $color__lgrey_93;
      }
      & > .title {
        padding: 0;
        margin-bottom: 16px;
      }
      & > div.content-wrapper {
        @include font__body_md;
      }
      & > div.button-wrapper {
        display: flex;
        gap: 8px;
        & > button {
          flex: 1;
          @include tblm-button-normal;
        }
      }
      &.continue {
        & > div.content-wrapper {
          @include font__body_md;
          & > .emphasis {
            @include font__title_32;
            margin-top: 24px;
            margin-bottom: 28px;
            margin-right: 124px;
            word-break: keep-all;
          }
        }
      }
    }
  }

  .footer-multiple-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;

    .footer {
      padding: 11px 8px;
      cursor: pointer;

      &.success {
        color: var(--primary-color);
      }

      &.default {
        color: $color__grey_66;
      }

      &.disabled-footer-btn {
        cursor: not-allowed;
        color: $color__grey_66;
      }

      &.error {
        color: $color_error;
      }
    }
  }
}
