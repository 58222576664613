@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.menuinformation-wrapper {
  .detail-content {
    margin: 16px 20px 0;
    padding: 20px 16px 16px 15px;
    display: flex;
    flex-direction: column;
    background: $color__white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    & > .detail-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        width: 100%;

        & > div:first-child {
          &.required {
            &::after {
              content: '*';
              line-height: 30px;
              padding-left: 6px;
              color: $color_error;
            }
          }
        }
        & > div:last-child {
          max-width: 191px;
          width: 100%;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .menuinformation-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
  .menuinformation-body {
    .body-title {
      margin: 0 0 20px 0;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }
    .con {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32%;
        height: 32px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        background: $color__default;
        border-radius: 100px;
        color: $color__white;
        span {
          color: var(--accent-color);
        }
      }
      :nth-child(3n - 1) {
        margin: 0 2px;
      }
      :nth-child(n + 4) {
        margin: 12px 0 0 0;
      }
    }
  }
}

.menuinformation-modal {
  .title {
    padding: 0 20px 16px 20px;
  }

  .content {
    .menu-group-wrapper {
      background-color: $color__bg_98;
      padding: 0 20px;
      .menu-group-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid $color__lgrey_89;
        .group-header {
          padding: 29px 0 5px 0;
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            width: 32px;
            height: 32px;
            background: $color__white;
            border: 1px solid $color__lgrey_89;
            border-radius: 2px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}

.menu-item-list {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  gap: 12px;

  & > div:first-of-type {
    flex: 1;
  }
}

.menu-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  // height: 40px;
}

.modal-content {
  // height: 100% !important ;
}
