@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.reservation-info-wrapper {
  margin: 20px 16px 0;
  padding: 20px 16px;
  background: $color__white;
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  .reservation-info-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
    .essential {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #e35b5b;
    }
  }
  .reservation-info-content {
    margin-top: 8px;
    .essential {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #f02b28;
      margin-left: 6px;
    }
    > div:not(.progress-bar) {
      padding: 22px 0;
    }
    > div {
      display: flex;
      align-items: baseline;
      gap: 8px;
      > :nth-child(1) {
        // flex: 0.8;
        min-width: 104px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $color__black;
      }
      > :nth-child(2) {
        flex: 1;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        > div :nth-child(n + 2) {
          background-color: red;
        }
      }

      button {
        padding: 8px;
        font-weight: 500;
        font-size: 13px;
        border: 0;
        line-height: 16px;
        border-radius: 5px;
        background-color: $color__lgrey_96;
      }
    }
  }
  .progress-bar {
    height: 1px;

    /* lgrey_96 */

    background: $color__lgrey_96;

    /* Inside auto layout */
    margin: 0 !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  & .error-message {
    color: $color_error;
    display: block;
  }
}
.list__filter_content {
  margin: 0;
  padding: 0;
  color: $color__black;
  &.list__filter_group_list {
    min-height: 162px;
    max-height: calc(100vh - 352px);
    overflow: overlay;
    overflow: auto;
    padding: 0 24px;
    & > div {
      border-top: 1px solid $color__lgrey_93;
      &:first-child {
        border-top: 0;
      }
      & > label {
        display: flex;
        padding: 16px 4px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        cursor: pointer;
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        & > i {
          margin-left: auto;
        }
      }
    }
  }
}
