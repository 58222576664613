/* preset classes */
.font__title_32 {
  @include font__title_32;
}
.font__title_32_m {
  @include font__title_32_m;
}
.font__title_32_sb {
  @include font__title_32_sb;
}
.font__title_20 {
  @include font__title_20;
}
.font__title_20_m {
  @include font__title_20_m;
}
.font__title_20_sb {
  @include font__title_20_sb;
}
.font__title_19 {
  @include font__title_19;
}
.font__title_19_m {
  @include font__title_19_m;
}
.font__title_19_sb {
  @include font__title_19_sb;
}
.font__title_19_b {
  @include font__title_19_b;
}
.font__subtitle {
  @include font__subtitle;
  font-weight: 600;
}
.font__subtitle_m {
  @include font__subtitle_m;
}
.font__subtitle_sb {
  @include font__subtitle_sb;
}
.font__subtitle1 {
  @include font__subtitle1;
}
.font__subtitle1_m {
  @include font__subtitle1_m;
}
.font__subtitle1_sb {
  @include font__subtitle1_sb;
}
.font__body_md {
  @include font__body_md;
}
.font__body_md_m {
  @include font__body_md_m;
}
.font__body_md_sb {
  @include font__body_md_sb;
}
.font__body_sm {
  @include font__body_sm;
}
.font__body_sm_m {
  @include font__body_sm_m;
}
.font__body_sm_sb {
  @include font__body_sm_sb;
}
.font__small {
  @include font__small;
}
.font__small_m {
  @include font__small_m;
}
.font__small_sb {
  @include font__small_sb;
}
.font__s_small {
  @include font__s_small;
}
.font__s_small_m {
  @include font__s_small_m;
}
.font__s_small_sb {
  @include font__s_small_sb;
}
.font__ss_small {
  @include font__ss_small;
}
.font__ss_small_m {
  @include font__ss_small_m;
}
.font__ss_small_sb {
  @include font__ss_small_sb;
}
