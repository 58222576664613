@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 8px;
  padding-top: 16px;
  height: min-content;
  max-height: 100%;

  label.facilitiesCheck {
    flex-shrink: 0;
    flex-grow: 0;
    user-select: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > input {
      display: none;
      & + i {
        font-style: inherit;
        border: 1px solid $color__lgrey_89;
        border-radius: 100px;
        padding: 7px 15px;
        display: inline-block;
        // transform: translateY(-1.5px);
      }
      &:checked {
        &[type='checkbox'] + i {
          color: $color__white;
        }
      }
      &:checked {
        &[type='checkbox'] + i {
          background-color: $color__default;
          border-color: transparent;
        }
      }
    }
  }
}
