@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.tabs-wrapper {
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: pre;
  background: $color__white;
  gap: 16px;
  padding: 0 20px;
  z-index: 1;

  &.is-border {
    border-bottom: 1px solid $color__lgrey_93;
  }
  &.is-fill {
    & > .tabs-item {
      flex-grow: 1;
    }
  }
  &.is-fixed {
    position: sticky;
  }
  & > .tabs-item {
    position: relative;
    padding: 16px 4px 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color__grey_66;
    &.active {
      color: $color__default;

      &::after {
        position: absolute;
        bottom: 0;
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: $color__default;
      }
    }
  }
}
