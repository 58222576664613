@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.paid-deposit-wrapper {
    padding: 0 20px 20px;

    .price-info, .title {
        margin-bottom: 24px;
    }

    .price-info {
        h4 {
            @include font__body_sm_m;
        }
        
        span {
            @include font__small;
            padding-top: 4px;
            color: $color__grey_66;
        }
    }

    .refund-select-button-wrapper {
        margin-bottom: 16px;

        h4 {
            padding-bottom: 8px;
        }

        ul {
            display: flex;
            align-items: center;
            column-gap: 8px;

            li {
                @include font__small;
                flex: 1;
                padding-block: 8px;
                background-color: $color__lgrey_96;
                text-align: center;
            }
        }
    }

    .info-refund-deposit-desc {
        display: block;
        padding-top: 8px;

        &.white-space {
            padding-bottom: 24px;
        }
    }
}