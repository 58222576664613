.flex-container {
	display: flex;
}
.flex-col {
	flex-direction: column;
}
.flex-grow {
	flex-grow: 1;
}
.flex-grow-0 {
	flex-grow: 0;
}
.flex-container-center {
  display: flex;
  align-items: center;
}

.flex-space-between {
  justify-content: space-between;
}