.custom-check-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 20px 20px;

  & > .custom-check-item {
    width: 100%;
    border-bottom: 1px solid $color__lgrey_93;
    & > label {
      display: flex;
      padding: 16px 4px;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      cursor: pointer;
    }
    &.active {
      & > label {
        & > span {
          color: var(--primary-color);
        }
        & > input,
        i {
          opacity: 0;
        }
      }
    }
    &.disabled {
      & > label {
        & > span {
          color: $color__lgrey_89;
        }
      }
    }
    &:last-child {
      border: none;
    }
  }
}
