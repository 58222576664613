@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.table-view-container {
	.table-container {
		margin: 0 20px;
		padding: 20px 0;

		.table-header {
			margin-bottom: 16px;
			max-width: 100%;
			width: fit-content;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
      // font-weight: 600;
		}
	}
}
