/* responsive */
$breakpoint__mobile: 360px;
$breakpoint__tablet: 768px;
$breakpoint__desktop: 1024px;
$breakpoint__xlg: 1280px;

$max-width: 480px;
$min-width: 320px;

/* font-family */
$font_default_alternative_family: -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
$font_default_family: Pretendard, $font_default_alternative_family;

$font__size11: 11px;
$font__size12: 12px;
$font__size13: 13px;
$font__size15: 15px;
$font__size17: 17px;
$font__size19: 19px;
$font__size20: 20px;

/* font-weight */
$font__400: 400;
$font__500: 500;
$font__600: 600;
$font__700: 700;
$font__regular: $font__400;
$font__semibold: $font__600;
$font__bold: $font__700;

/* colors */
$color__default: #1d1d1d;
// Primary Color
$color__p_blue: #3f73f2;
$color__p_sky: #5cb5fd;
// Secondary Color
$color__s_orange: #ff8d3b;
$color__s_green: #1d9772;
$color__s_coral: #e35b5b;
// Gray_Black Color
$color__black: #212121;
$color__dgrey_38: #4f5861;
$color__grey_66: #939ca9;
$color__grey_75: #b1b8c0;
$color__lgrey_89: #e1e2e4;
$color__lgrey_93: #ededed;
$color__lgrey_96: #f4f4f4;
$color__bg_98: #f7f8f9;
$color__white: #ffffff;
// Error Color
$color_error: #e5281e;
// Gradient
$color_gra_blue: linear-gradient(89.88deg, #297cf6 0.1%, #1344cb 99.89%);
$color_gra_lavender: rgba(0, 18, 178, 0.4);
