@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialNaverKakao styles

.tutorial-naverkakao {
  width: 100%;
  & > div.tutorial-naverkakao-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    & > a {
      @include font__subtitle1_sb;
      margin-top: auto;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
      text-align: center;
      text-decoration: underline;
      text-underline-offset: 1px;
    }
  }
  .button-container {
    position: absolute;
    bottom: 0;
    margin: 20px auto 0;
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid $color__lgrey_89;
    z-index: 6;
    & > button {
      @include font__subtitle1_sb;
      height: 48px;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;

      &.prev {
        @include tblm-button-gray;
      }

      &.next {
        @include tblm-button-color(var(--primary-color-rgb));
        padding: 0;
        color: $color__white !important;
      }
    }
  }
}
.title.with-note {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  .title-note {
    color: $color__grey_66 !important;
  }
}

.form-wrapper {
  display: flex;
  gap: 16px;
  margin: 0;
  flex-direction: column;
  padding: 0 20px 16px;
  li {
    height: 64px;
    display: flex !important;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      height: 40px;
      &.custom-input-wrapper {
        align-items: start;
      }
      &.form-label {
        width: 106px;
        flex-shrink: 0;
        flex-grow: 0;
        @include font__body_sm;
      }
      &.form-value {
        position: relative;
        display: flex;
        flex: 1;
        & > input {
          display: block;
          height: 40px;
          border: 1px solid $color__lgrey_89;
          border-radius: var(--textfield-radius);
          flex: 1;
        }
        &.three {
          & > span {
            margin: 0 3px;
          }
          & > input {
            width: calc(33.3333% - 10px);
          }
        }
      }
    }
  }
}
.form-button-container {
  bottom: 0;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid $color__lgrey_89;

  & > button {
    @include font__subtitle1_sb;
    height: 48px;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;

    &.prev {
      @include tblm-button-gray;
    }

    &.next {
      @include tblm-button-color(var(--primary-color-rgb));
      padding: 0;
      color: $color__white !important;
    }
  }
}
