@import '../../utils/variables';
@import '../../utils/boxshadow';
@import '../../utils/filters';
@import '../../utils/mixin';
@import '../../utils/responsive';
@import '../../utils/textstyle';

@import '../../presets/buttons';

.edit-information-wrapper {
  background-color: $color__lgrey_96;
  .edit-information-header {
    display: flex;
    align-items: center;
    padding: 18px;
    background: $color__white;
    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      margin-left: 20px;
    }
  }
  .edit-information-body {
    margin: 16px 0 89px;
    padding: 20px;
    background: $color__white;
    .business-Number {
      .input-wrapper {
        display: flex;
        align-items: center;
        :nth-child(1) {
          margin-right: 3px;
        }
        :nth-child(2) {
          margin: 0 3px;
        }
        :nth-child(3) {
          margin-left: 3px;
        }
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 64px;
      padding: 20px 0;
      border-bottom: solid 1px $color__lgrey_96;

      > :first-child:not(.w-full) {
        min-width: 104px;
        align-items: flex-start;
        margin-right: 8px;
      }
      > :last-child {
        width: 100%;
      }
      .radius {
        display: flex;
        flex-wrap: wrap;
        > div {
          background: $color__default;
          color: $color__white;
          padding: 8px 16px;
          border-radius: 100px;
        }
      }
    }
    .human {
      display: flex;
      align-items: center;
      margin-top: 14px;
      span {
        margin-left: 8px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
    }
    .store-explanation {
      align-items: flex-start;

      :last-child {
        height: 240px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    // min-height: 89px;
    width: 100%;
    max-width: 480px;
    padding: 20px;
    background: $color__white;
    border-top: 1px solid $color__lgrey_93;
    button {
      width: 100%;
    }
  }
}
