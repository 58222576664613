@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.my_store_all_wrapper {
    background-color: $color__white;
    height: 100%;

    .my_store__header {
        position: sticky;
        top: 0;
        @include font__title_19_sb;
        background-color: $color__white;
        padding: 16px 24px;
    }
    
    .my_store__container {
        display: flex;
        flex-direction: column;
        height: calc(100% - 56px - 56px);
        background: $color__bg_98;
        border-radius: 30px 30px 0px 0px;
        padding: 20px;
        
        .my_store__content {
            @include font__subtitle1_sb;
            background-color: $color__white;
            border-radius: 12px;
            width: 100%;
            padding-block: 8px;
            overflow: auto;

            &.activeFirstTab {
                border-top-left-radius: 0px;
            }

            li {
                margin-inline: 16px;
                padding-left: 8px;
                border-bottom: 1px solid #F4F4F4;
                min-height: 72px;
                display: flex;
                align-items: center;

                &.link {
                    justify-content: space-between;

                    &::after {
                        content: '';
                        background: url('/assets/images/icons/Arrow_Right.svg') no-repeat;
                        display: block;
                        width: 16px;
                        height: 16px;
                    }
                }

                &.use_sub_title_wrapper {
                    .use_sub_title {
                        em {
                            @include font__body_sm;
                            margin-top: 8px;
                            color: $color__grey_66;
                            display: block;
                        }

                    }
                }
            }
        }
    
        .my_store__info {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 17px 20px;

            h2 {
                @include font__subtitle_sb;
                margin-bottom: 4px;
            }

            em {
                @include font__body_sm_m;
            }
        }

        .sub_title__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            padding-inline: 12px;

            h3 {
                @include font__subtitle_sb;
            }

            span {
                @include font__small;
                line-height: 19px;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    background: #3399FF;
                    border-radius: 24px;
                    color: var(color__dgrey_38);
                }
            }
        }

        .navigation_menu__wrapper {
            display: flex;
            margin-block: 20px;
            height: 100%;

            .navigation_menu__content {
    
                ul {
                    display: flex;
                    flex-direction: column;
    
                    li {
                        @include font__subtitle1_sb;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 76px;
                        min-width: 68px;
                        background: #F1F1F1;
                        border-radius: 12px;
                        padding: 14px 8px;
                        line-height: 18px;
                        min-height: 64px;
                        letter-spacing: -0.5px;
                        margin-right: 8px;
                        word-break: keep-all;
                        white-space: pre;
                        text-align: center;
                        margin-bottom: 8px;
                        color: $color__grey_75;
                        
                        &.active_menu {
                            min-width: 84px;
                            margin-right: 0;
                            background-color: $color__white;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
                            border-radius: 12px 0px 0px 12px;
                            color: var(--primary-color);

                            &::after {
                                content: '';
                                display: inline-block;
                                width: 8px;
                                height: 100%;
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}