.management-day-wrapper {
  display: flex;
  flex-direction: column;

  .left-area {
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > div:last-child {
      color: $color__grey_75;
    }
  }
  .right-area {
    display: flex;
    align-items: center;
    color: $color__grey_75;
    & > svg {
      margin-top: -1px;
      & > path {
        fill: $color__grey_75;
      }
    }
  }
  & > .management-day-header {
    position: sticky;
    top: 56px;
    display: flex;
    justify-content: space-between;
    padding: 32px 20px 24px 20px;
    margin-bottom: 8px;
    background: $color__white;
    z-index: 2;
    .left-area {
      & > div {
        color: $color__default;
      }
    }
  }
  & > .management-day-content {
    display: flex;
    flex-direction: column;
    background: $color__white;
    padding: 24px 20px 20px;

    & .management-day-title {
      padding-bottom: 16px;
    }

    & > .management-day-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 23px 0;
      border-bottom: 1px solid $color__lgrey_93;

      .left-area {
        & > div {
          color: $color__default;
        }
      }
      .right-area {
        @include font__small;
        color: $color__grey_66;
      }
      &.disabled {
        .left-area {
          & > div {
            color: $color__grey_75;
          }
        }
      }
    }
  }
}
