@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.customeradditionalinformation-wrapper {
  margin: 16px 20px 0;
  padding: 16px 20px;
  background: $color__white;
  overflow: auto;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 4%);
  border-radius: 8px;
  .customeradditional-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 8px;
  }
  .customeradditionalinformation-body {
    margin-bottom: 12px;

    .custom-check-wrapper {
      padding: 0;
    }

    > div {
      display: flex;
      align-items: center;
      // margin-top: 20px;
      padding-block: 12px;
      > :nth-child(1) {
        // flex: 0.9;
        min-width: 104px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
      > :nth-child(2) {
        flex: 1;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  .reservation-message-box {
    .reservation-message {
      margin-top: 16px;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 16px;
      > :nth-child(1) {
        margin-bottom: 8px;

        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
      > :nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
      }
      .button-wrapper {
        button {
          margin-left: 8px;
        }
      }
    }
  }
}

.reservationInformationMassage {
  .content {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    & > b {
      @include font__small;
      display: block;
      padding-top: 5px;
      color: $color__grey_66;
    }
  }

  .bottom-rayout {
    text-align: end;
    button {
      font-size: 15px;
      font-weight: 600;
      color: $color__black;
      line-height: 18px;
      letter-spacing: -0.5px;
      border: 0;
      cursor: pointer;
      background-color: $color__white;
    }
  }
}
