@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.week-calendar-container {
  display: flex;
  align-items: center;
  padding: 4px 0 16px;
  .week-calendar-sticky-header {
    position: sticky;
    left: 0;
    width: 58px;
    .view-date-text {
      display: flex;
      align-items: center;
    }
  }
  .week-calendar {
    display: flex;
    width: 100%;
    position: relative;
    overflow: scroll;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    //scroll-behavior: smooth;
    .calendar-day {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 7px;
      .calendar-day-text {
        color: $color__grey_66;
        margin-bottom: 4px;
      }
      .date-box {
        min-width: 28px;
        min-height: 28px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color__dgrey_38;
        margin-bottom: 4px;
        &.today {
          border: 1px solid $color__black;
          border-radius: 1000px;
        }
        &.selected {
          color: $color__white;
          background-color: var(--primary-color);
          border-radius: 50%;
          border: 1px solid var(--primary-color);
        }
      }
    }
  }
}
