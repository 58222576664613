.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.none {
  display: none !important;
}
.block {
  display: block;
}
.hidden {
  overflow: hidden;
}
.w-full {
  width: 100%;
}
.m-w-full {
  max-width: 100% !important;
}
.h-full {
  height: 100%;
}
.slide-x {
  width: 100%;
  overflow-x: auto;
  white-space: pre;
}
.white-pre {
  white-space: pre;
}
.no-border {
  border: none !important;
}
.border-top {
  border-top: 1px solid $color__lgrey_96;
}
.no-padding {
  padding: 0 !important;
}
.keep-all {
  word-break: keep-all;
}
.pointer {
  cursor: pointer;
}

.bg-white {
  background: $color__white;
}

@for $i from 1 through 50 {
  .gap-#{$i} {
    gap: $i + px;
  }
  .mg-#{$i} {
    margin: $i + px;
  }
  .mb-#{$i} {
    margin-bottom: $i + px;
  }
  .pd-#{$i} {
    padding: $i + px;
  }
  .pt-#{$i} {
    padding-top: $i + px;
  }
  .pb-#{$i} {
    padding-bottom: $i + px;
  }
}

* {
  scrollbar-width: none; /* Firefox */
  font-family: $font_default_family;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  // transform: translate3d(0,0,0);
  // -webkit-transform: translateZ(0);
}

*::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, Opera*/
  width: 0 !important;
  height: 0;
  background: transparent;
}

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  letter-spacing: 0;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  // transform: translate3d(0,0,0);
  // -webkit-transform: translateZ(0);
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.keyboard-up {
  .keybord-toggler {
    display: none;
  }
  .user-search-wrapper {
    height: 100%;
  }
}
