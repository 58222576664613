@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// ReservationListCalendar styles
.calendarOnListWrapper {
  position: relative;
  width: 100%;
  &:before {
    content: '';
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }
  .calendarOnList {
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: $max-width;
    height: 100vh;
    overflow: overlay;
    overflow: auto;
  }
}
