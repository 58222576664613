@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.line {
  height: 1px;
  background: $color__lgrey_96;
  margin: 0 !important;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
