@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.menulist-wrapper {
  width: 100%;
  max-width: 480px;
  max-height: calc(56px + 48px);
  position: fixed;
  top: 0px;
  overflow: auto;
  background-color: $color__white;
  z-index: 2;
  .menu {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    white-space: nowrap;
    padding: 16px 4px 12px;
    margin: 0 16px;
  }
  .current {
    border-bottom: solid 1px;
  }
  .current-bar {
    background-color: red;
  }
  .backbutton-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
  }
}
