@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.notification_exception__container {
    .notification_exception__main {
        height: calc(100% - 89px - 55px);
        .item_count_info {
            @include font__subtitle1_sb;
            padding: 24px 20px 16px;
            background-color: $color__white;
        }

        .notification_exception__content {
            ul {
                background-color: $color__white;
            }

            li {
                background-color: $color__white;
                min-height: 76px;
                margin-inline: 20px;
                display: flex;
                align-items: center;
                padding-block: 10px;
                border-bottom: 1px solid #F1F1F1;
                

                .content_title {
                    width: 100%;

                    h5 {
                        @include font__subtitle1_sb;
                    }
                    span {
                        @include font__body_sm;
                        color: $color__grey_66;
                        margin-top: 4px;
                    }
                }

                .button_wrap {
                    flex-shrink: 0;
                }
            }
        }
    }
}