@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialReservation styles
.tutorial-reservation {
  margin: 0;
  padding: 0;
  width: 100%;
  padding-inline: 20px;
  & > li {
    @include font__body_sm;
    padding: 16px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color__lgrey_96;
    color: $color__grey_66;
    &:first-child {
      border-top: 1px solid $color__lgrey_96;
    }

    &.timelist {
      padding-bottom: 20px;
    }

    & > div {
      height: 100%;
      align-items: center;

      span {
        @include font__subtitle_m;
        display: block;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      & em.possible-value {
        display: block;
        font-style: normal;
        padding-top: 8px;
      }
    }
    & > div:not(.modify) {
      flex: 1;
    }
    div.modify {
      @include font__body_sm;
      margin-left: auto;
      width: fit-content;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      color: $color__grey_75;
      cursor: pointer;
      &::after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        // opacity: .75;
        -webkit-mask-image: url('../../assets/images/icons/Arrow_Right.svg');
        mask-image: url('../../assets/images/icons/Arrow_Right.svg');
        background-size: 100%;
        background-color: $color__grey_75;

        // filter: invert(82%) sepia(10%) saturate(202%) hue-rotate(172deg) brightness(88%) contrast(91%);
      }
    }
    &.timelist {
      width: 100%;
      display: block;
      & > div:first-child {
        width: 100%;
        display: flex;
      }
      & ul {
        margin: 33px 0 0 0;
        padding: 0;
        width: 100%;
        & > li {
          &:not(&:last-of-type) {
            margin-bottom: 32px;
          }

          & > p {
            @include font__body_md;
            padding-bottom: 16px;
          }
          // & > div {
          //   margin-top: 16px;
          //   display: grid;
          //   gap: 8px;
          //   grid-template-columns: repeat(auto-fill, 77.75px);
          //   & > button {
          //     @include font__body_sm;
          //     height: 26px;
          //     border: 1px solid $color__lgrey_93;
          //     border-radius: 4px;
          //     background-color: $color__white;
          //   }
          // }
        }
      }
    }
  }
}

.modal-time-button-wrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  & > button {
    flex-grow: 1;
    height: 40px;
    font-weight: 400;
    padding: 0px 8px;
    background: $color__white;
    border: 1px solid $color__lgrey_89;
    border-radius: var(--button-radius);
    min-width: 25%;
    &.active {
      color: $color__white;
      background-color: $color__black;
      border: transparent;
    }
  }
}
