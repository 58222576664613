@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialSelector styles
.tutorial-selector {
  margin: auto auto -20px;
  padding: 52px 0 20px 0;
  width: calc(100% + 40px);
  height: 300px;
  border-radius: 20px;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, .08);
  & > .title {
    white-space: pre-line;
    margin-bottom: 24px;
    @include font__subtitle_sb;
  }
}
