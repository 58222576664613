@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.reservation-off-wrapper {
  display: flex;
  flex-direction: column;
  padding: 56px 20px 40px 20px;
  gap: 10px;
  background: white;
  height: 100%;
  .reservation-off-content {
    .content-images {
      display: flex;

      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        max-width: 80%;
      }
    }
  }
}
