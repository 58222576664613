@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.table-item {
  padding: 12px 24px;
  background: $color__white;
  border: 1px solid $color__lgrey_89;
  border-radius: var(--textfield-radius);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  gap: 36px;
  & > .table-title,
  & > .no-reservation {
    padding: 4px 0px;
    gap: 8px;
    min-width: 48px;
    height: 30px;
    color: inherit;
  }
  & > .table-reservation-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    & > .table-reservation-item {
      padding: 4px 16px;
      background: $color__lgrey_96;
    }
  }
  &.active {
    background: $color__default;
    color: $color__white;
    border-color: transparent;
    & > .table-reservation-wrapper {
      & > .table-reservation-item {
        color: $color__white;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
