@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

@import './docs/AgreementDoc.scss';
// ListFilter styles

.agreement {
  padding: 0 20px 20px 20px;
  // max-width: 414px!important;
  max-height: 100% !important;
  & > .close {
    min-height: 24px;
    padding: 12px 0 !important;
  }
  .agreement_modal_title {
    @include font__title_32_sb;
    margin-bottom: 16px;
    color: $color__black;
    /* max-height: 40px; */
    height: 40px;
    white-space: pre;
    text-overflow: ellipsis;
    width: 100%;
    overflow-x: hidden;
    flex-shrink: 0;
    @media (max-width: 359px) {
      font-size: 29px;
      line-height: 32px;
    }
  }
  .agreement_modal_note {
    @include font(15px, $color__default, 600, 24px);
    color: $color__dgrey_38;
    margin-bottom: 32px;
  }
  .agreement_modal_body {
    height: calc(100vh - 373px);
    flex: 1;
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      align-items: center;
      & > input {
        display: none;
      }
      & > i {
        margin-right: 10px;
        background-image: url('/assets/images/icons/Check_Filter_Enabled_b.svg');
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
      & > input:checked + i {
        mask-image: url('/assets/images/icons/Check_Filter_Selected_b.svg');
        background: var(--accent-color);
      }
    }
    & > .agreement_modal_subtitle {
      @include font__title_19_b;
      height: 56px;
      line-height: 56px !important;
    }
    & > .allCheck {
      @include font__body_md_sb;
      width: 100%;
      height: 57px;
      display: flex;
      align-self: center;
      border-bottom: 1px solid #939ca9;
      color: #4f5861;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 16px 4px;

      & .allcheck_change_color {
        color: var(--primary-color);
      }
    }
    & > .agreement_list {
      margin: 0;
      padding: 0;
      // max-height: 226.5px;
      max-height: 234.5px;
      overflow: overlay;
      overflow: auto;
      & > li {
        @include font__body_md_m;
        display: flex;
        align-items: center;
        padding: 16px 4px;
        color: $color__dgrey_38;
        line-height: 24px;

        @media (max-width: 359px) {
          font-size: 15px;
          line-height: 18px;
        }

        &:not(&:last-of-type) {
          border-bottom: 1px solid $color__lgrey_93;
        }

        .agreement_title {
          & > span {
            color: var(--primary-color);
            margin-right: 4px;
          }
        }
        & > svg {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
    }
  }

  footer.agreement-btn-wrapper {
    max-width: 100vw;
    margin: 0 -20px;
    border-top: 1px solid $color__lgrey_93;

    button.list__filter_apply {
      @include font__subtitle1_sb;
      margin-top: 20px;
      width: calc(100% - 40px);
      margin-inline: 20px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: var(--button-radius);
      background-color: var(--primary-color);
      color: $color__white;
      cursor: pointer;
      flex-shrink: 0;
      flex-grow: 0;
      &:disabled {
        background-color: $color__lgrey_96;
        color: $color__black;
        cursor: not-allowed;
      }
    }
  }
}
