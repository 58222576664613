@function check($color: $color__grey_66) {
  @return "data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.21967 7.21967C3.51256 6.92678 3.98744 6.92678 4.28033 7.21967L6.75 9.68934L12.2197 4.21967C12.5126 3.92678 12.9874 3.92678 13.2803 4.21967C13.5732 4.51256 13.5732 4.98744 13.2803 5.28033L7.28033 11.2803C6.98744 11.5732 6.51256 11.5732 6.21967 11.2803L3.21967 8.28033C2.92678 7.98744 2.92678 7.51256 3.21967 7.21967Z' fill='%23#{str-slice(#{$color}, 2, 10)}'/%3E%3C/svg%3E%0A";
}
@mixin tblm-chip {
  padding: 0 8px;
  display: inline-flex;
  border-radius: 100px;
  user-select: none;
  & > i {
    display: block;
    align-self: center;
    background-position: center;
  }
}

div.tblm-chip {
  @include tblm-chip;
}
div.tblm-chip-filter-check {
  @include tblm-chip;
  @include font__small_sb;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: auto;
  min-height: 32px;
  // line-height: 32px;
  background-color: #{$color__lgrey_96};
  color: #{$color__grey_66};
  writing-mode: unset;
  &::before {
    margin-right: 2px;
    min-width: 16px;
    min-height: 16px;
    content: '';
    display: block;
    align-self: center;
    background-image: url(check());
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  &.selected {
    background-color: #{$color__default};
    color: #{$color__white};
    &::before {
      mask-image: url(check());
      background-color: var(--accent-color);
    }
  }
}
input[type='checkbox'].tblm-chip-filter-check-handle:checked + div.tblm-chip-filter-check {
  background-color: #{$color__default};
  color: #{$color__white};
  &::before {
    mask-image: url(check());
    background: var(--accent-color);
  }
}

div.tblm-chip-filter-menu {
  @include tblm-chip;
  @include font__subtitle1_sb;
  padding: 8px 12px;
  // height: 34px;
  // line-height: 34px;
  background-color: #{$color__lgrey_96};
  box-shadow: 0 0 0 1px inset #{$color__lgrey_89};
  color: #{$color__grey_66};
  cursor: default;
  & > i.remove {
    margin-left: 2px;
    width: 16px;
    height: 16px;
    display: none;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 4L8 8M8 8L12 4M8 8L4 12M8 8L12 12' stroke='%23939CA9' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    cursor: pointer;
    transform: translateY(-1px);
  }
  & > span {
    display: none;
  }
  &.selected {
    background-color: #{$color__grey_66};
    box-shadow: unset;
    color: #{$color__white};
    & > i.remove {
      display: block;
    }
    & > span {
      margin-left: 4px;
      display: block;
      color: #{var(--accent-color)};
    }
  }
}

div.tblm-chip-filter-num {
  @include tblm-chip;
  @include font__small_sb;
  padding: 0 9.5px;
  min-width: 30px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #{$color__lgrey_89};
  outline: none;
  background-color: #{$color__white};
  color: #{$color__black};
  cursor: pointer;
  justify-content: center;
  &[contenteditable] {
    outline: none;
    cursor: text;
    &:empty::before {
      content: attr(placeholder);
    }
    &:empty:focus {
      &::before {
        content: '';
      }
    }
  }

  &.selected {
    border: 1px solid transparent;
    background-color: $color__default;
    color: $color__white;
  }
}

@mixin tblm-tag-color-user {
  @include tblm-chip;
  @include font__s_small;
  height: 22px;
  line-height: 12px;
  border: 1px solid var(--bdr);
  background-color: var(--bg);
  color: var(--txt);
  cursor: pointer;
  &::before {
    content: var(--fix);
  }
}
.tblm-tag-color-user {
  @include tblm-tag-color-user;
}

.tblm-tag-tag {
  @include tblm-tag-color-user;
  --fix: '#';
  --bg: #{$color__lgrey_96};
  --bdr: #{$color__lgrey_96};
  --txt: #{$color__dgrey_38};
  &.link {
    padding: 0 7px;
    --bdr: #{$color__grey_66};
    --bg: #{$color__white};
  }
}
.tblm-tag-color {
  @include tblm-chip;
  @include font__s_small;
  height: 22px;
  line-height: 22px;
  border: 1px solid #{$color__grey_66};
  background-color: #{$color__white};
  color: #{$color__grey_66};
  cursor: pointer;

  &.on {
    border: 1px solid #{var(--primary-color)};
    background-color: #{var(--primary-color)};
    color: #{$color__white};
  }
}

/*
FilterChipMenu, FilterChipNum 외에는 일반 엘리먼트에 클래스 추가하여 사용한다.

<div class="tblm-chip-filter-check">입장</div>
<div class="tblm-chip-filter-check selected">접수</div>

<FilterChipMenu name={'메뉴명'} selected={true} count={2} onRemove={onRemove}/>
<FilterChipNum name={''} selected={false} editable={true}  onChange={onChange}/>

<button class="tblm-tag-tag">첫방문</button>
<button class="tblm-tag-tag link">첫방문</button>
<button class="tblm-tag-color">Off</button>
<button class="tblm-tag-color on">On</button>
*/
