@import '../../utils/variables';
@import '../../utils/boxshadow';
@import '../../utils/filters';
@import '../../utils/mixin';
@import '../../utils/responsive';
@import '../../utils/textstyle';

@import '../../presets/buttons';

.information-wrapper {
  background-color: $color__lgrey_96;
  .information-header {
    display: flex;
    align-items: center;
    padding: 18px;
    background: $color__white;
    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      margin-left: 20px;
    }
  }
  .information-body {
    margin: 16px 0 89px;
    padding: 16px 20px 20px;
    background: $color__white;
    > div {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      // min-height: 64px;
      padding: 21px 0;
      border-bottom: solid 1px $color__lgrey_96;
      > :nth-child(1):not(.w-full) {
        // flex: 0.5;
        min-width: 104px;
        margin-right: 8px;
      }
      > :last-child {
        // flex: 0.8;
        width: 100%;
      }
      & .value-not-exist {
        font-weight: 400;
        color: $color__grey_75;
      }
    }
    .nomargin {
      & > .wrapper {
        padding-top: 1px;
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    // min-height: 89px;
    width: 100%;
    max-width: 480px;
    padding: 20px;
    background: $color__white;
    border-top: 1px solid $color__lgrey_93;
    button {
      width: 100%;
    }
  }
}
