/* button default color */
// rgba를 colorSet으로 설정하기 위해 모든 color의 params를 rgba로 묶습니다.
button {
  background-color: $color__lgrey_96;
}

/* Mixins */
@mixin tblm-button-base {
  @include font__subtitle1_sb;
  border: 0;
  border-radius: var(--button-radius);
  position: relative;
  overflow: hidden;
  color: $color__dgrey_38;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
@mixin tblm-button-normal {
  @include tblm-button-base;
  line-height: 18px;
  height: 48px;
  padding: 0 16.5px;
}
@mixin tblm-button-small {
  @include tblm-button-base;
  height: 32px;
  padding: 0 8px;
  font-size: 13px;
}
@mixin tblm-button-color($maincolor: var(--primary-color-rgb), $subcolor: $color__white) {
  @include tblm-button-base;
  position: relative;
  background-color: rgba($maincolor, 1);
  color: rgba($subcolor, 1);
  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__white, 0.08);
    }
  }
  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__white, 0.32);
    }
  }

  &:disabled {
    background-color: rgba($maincolor, 50%);
    color: rgba($subcolor, 50%);
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__white, 56%);
    }
  }
}
@mixin tblm-button-floating($bgcolor: $color__default, $color: $color__white) {
  @include tblm-button-normal;
  position: relative;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: rgba($bgcolor, 1);
  color: rgba($color, 1);

  & > i {
    &::before,
    &::after {
      background-color: rgba($color, 1);
      width: 2px;
      height: 2px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 1px;
      content: '';
    }

    &::before {
      width: 16px;
    }

    &::after {
      height: 16px;
    }
  }

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 0.08);
    }
  }

  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 0.32);
    }
  }

  &:disabled {
    background-color: rgba($bgcolor, 50%);
    color: rgba($color, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 0.56);
    }
  }
}
@mixin tblm-button-gray {
  @include tblm-button-base;
  position: relative;

  background-color: $color__lgrey_96;
  // color: $color__black;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__default, 0.04);
    }
  }

  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__default, 0.12);
    }
  }

  &:disabled {
    background-color: rgba($color__lgrey_96, 2%);
    color: rgba($color__default, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color__default, 0.2);
    }
  }
}
@mixin tblm-button-outlined($color: var(--primary-color-rgb)) {
  @include tblm-button-base;
  position: relative;
  background-color: $color__white;
  color: rgba($color, 1);
  box-shadow: 0 0 0 1px inset rgba($color, 1);

  &:hover {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 4%);
    }
  }

  &:active {
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 12%);
    }
  }

  &:disabled {
    background-color: $color__white;
    color: rgba($color, 50%);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color, 20%);
    }
  }
}
