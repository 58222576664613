@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.reservation_nav {
  margin: 0;
  padding: 0;
  background-color: $color__white;
  min-height: 100vh;

  &__list {
    @include font(inherit, $color__default, 700, 19px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 16px 27px 24px;
    margin: 0px 16px;
    border-bottom: 1px solid $color__lgrey_96;
    letter-spacing: -0.3px;

    & > svg > path {
      fill: $color__grey_75;
    }
  }
}
