@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialTable styles
.tutorial-page.unfixed {
  position: relative;
  margin-top: 56px;
  padding: 0;
  width: 100%;
  height: calc(100vh - 56px);
  display: block;
  overflow: overlay;
  overflow: auto;
  border-radius: 0;

  scroll-behavior: smooth;
  & ~ .fake {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 56px;
      display: block;
      background-color: $color__white;
    }
  }

  & > .tutorial-table-content__head {
    z-index: 2;
    padding: 0 20px 32px;
    width: 100%;
    display: block;
    background-color: $color__white;
    & > .tutorial-question {
      @include font__title_32;
      height: 120px;
      font-weight: 600;
      white-space: pre-line;
    }
    &:before {
      content: '';
      position: absolute;
      top: -56px;
      right: 0;
      left: 0;
      height: 56px;
      display: block;
      background-color: $color__white;
    }
  }
  & > .tutorial-table-content__body {
    padding: 24px 0 0;
    row-gap: 32px;
    background-color: $color__lgrey_96;
    & > .button-container {
      position: sticky;
      bottom: 0;
      margin: -36px auto 0;
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-top: 1px solid $color__lgrey_89;
      background-color: $color__white;
      z-index: 6;
      & > button {
        @include font__subtitle1_sb;
        @include tblm-button-color(var(--primary-color-rgb));
        padding: 0;
        height: 48px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        color: $color__white !important;
      }
    }
    & > button {
      &.scrollbottom {
        @include tblm-button-floating($color__lgrey_89, $color__black);
        position: sticky !important;
        bottom: 121px;
        margin: 20px auto calc(36px + 32px) calc(100% - 56px);
        &.up > svg {
          transform: rotate(180deg);
        }
      }
      &.addTableGroup {
        width: calc(100% - 40px);
        margin-inline: 20px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $color__grey_75;
        border-radius: var(--button-radius);
        background-color: $color__white;
        z-index: 6;
        color: $color__black;
        & > svg {
          margin-right: 2px;
        }
      }
    }
    .table_group {
      margin-right: 20px;
      margin-bottom: 32px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background-color: $color__white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);

      padding-inline: 16px;
      &:first-child {
        margin-top: 0;
      }
      &:nth-last-of-type(2) {
        margin-bottom: 16px;
      }
      & > .table_group_head {
        @include font__title_20_sb;
        position: sticky;
        top: 0;
        padding-block: 16px;
        height: 56px;
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: $color__white;
        & > svg {
          margin-left: 5px;
        }
        & > span {
          flex: 1;
        }
        &[stuck] {
          & > svg {
            margin-left: auto;
          }
          z-index: 2;
          margin: 0 -36px;
          padding: 14px 20px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        }
      }
      & > .table_group_addtable {
        @include font__subtitle_sb;
        @include tblm-button-color(var(--primary-5-rgb), var(--primary-color-rgb));
        margin: 16px 0;
        width: auto;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > svg {
          margin-right: 2px;
        }
      }
      & > .table_table {
        padding-bottom: 16px;
        &:last-of-type {
          padding-bottom: 0;
        }
        & > .new-table-info-wrapper {
          margin: 0;
          padding: 20px;
          border-radius: 8px;
          background-color: $color__lgrey_93;
        }
      }
    }
  }
}
