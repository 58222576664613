@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.header-back-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 20px;
  background: $color__white;
  z-index: 3;

  & .font__subtitle {
    letter-spacing: 1px;
  }

  &.is-fixed {
    position: sticky;
    top: 0;
  }
}
