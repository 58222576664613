@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.custom-search-input-wrapper {
  display: flex;
  width: 100%;

  & > .input-area {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    & > input {
      padding: 9px 40px;

      width: 100%;
      height: 38px;

      border: 1px solid $color__lgrey_96;
      border-radius: var(--textfield-radius);

      color: $color__default;
      background: $color__lgrey_96;

      &:focus,
      &:active {
        border-width: 2px;
        box-sizing: border-box;
      }
      &:disabled {
        background: $color__lgrey_96;
        border: 1px solid $color__lgrey_89;
        color: $color__grey_75;
      }
      &::placeholder {
        color: $color__grey_75;
      }
      &[error] {
        border: 1px solid $color_error;
        & + span {
          color: $color_error;
          padding: 0 12px;
        }
        &:focus,
        &:active {
          border-width: 2px;
          box-sizing: border-box;
        }
      }
    }
    & > svg {
      position: absolute;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:nth-child(1) {
        left: 0;
      }
      &:nth-child(3) {
        right: 0;
      }
    }
  }
}
