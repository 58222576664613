@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.calendar-sticky-header {
  position: sticky;
  background-color: $color__white;
  padding: 8px 20px;
  z-index: 1;

  .view-date-text {
    padding-bottom: 16px;
    .icon-arrow-down {
      vertical-align: middle;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  .week-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;

    .week-header-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color__dgrey_38;
      margin-bottom: 8px;
    }
  }
}

.calendar-container {
  padding: 0 20px;

  &:last-child {
    padding-bottom: 20px;
  }
}

.calendar-month {
  padding-bottom: 8px;
}

.calendar-week {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  border-top: 1px solid $color__lgrey_96;
  padding-bottom: 20px;

  .calendar-day {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    cursor: pointer;
    padding-top: 4px;

    .date-box {
      min-width: 28px;
      min-height: 28px;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color__dgrey_38;
      margin-bottom: 2px;

      &.selected {
        color: $color__white !important;
        background-color: var(--primary-color);
        border-radius: 50%;
      }

      &.today {
        border: 1px solid $color__black;
        border-radius: 1000px;
      }

      &.today.selected {
        border: none;
      }
      &.saturday {
        color: #2150c4;
      }
      &.sunday,
      &.holiday {
        color: #eb493f;
      }
    }
    &.disabled {
      .date-box {
        color: #b1b8c0;

        &.selected {
          color: white;
        }

        &.saturday {
          color: var(--primary-40);
        }
        &.sunday,
        &.holiday {
          color: #f7b6b2;
        }
      }
    }
    .order-info {
      color: $color__grey_66;
      text-align: center;
    }
  }

  &:last-child {
    padding: 0;
  }
}
