@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.name-phone-list-wrapper {
  display: flex;
  flex-direction: column;
  & > .name-phone-list-item {
    display: flex;
    flex-direction: column;
    padding: 17px 0 14px 0;
    border-top: 1px solid $color__lgrey_93;
    gap: 16px;
    cursor: pointer;

    .top-area {
      display: flex;
      align-items: center;
      gap: 8px;

      & > div + svg {
        margin-left: auto;
      }
    }
    .bottom-area {
      display: flex;
      flex-direction: column;
      gap: 12px;
      & > div {
        display: flex;
        gap: 8px;
        & > .tag {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 6px;
          width: 56px;
          height: 20px;
          border: 1px solid $color__lgrey_89;
          border-radius: 2px;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid $color__lgrey_93;
    }
  }
}
