@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.reservation-history-container {
    padding: 24px 20px;

    > ul {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block: 14px;
            border-bottom: 1px solid $color__lgrey_96;

            .content-wrap {
                h5 {
                    @include font__subtitle1_sb;
                    margin-bottom: 8px;
                }

                p {
                    @include font__small;
                    margin-bottom: 2px;
                }

                b {
                    @include font__small;
                }
            }

            &.scheduledVisit {
                .content-wrap {
                    h5, p, b {
                        color: $color__grey_66;
                    }
                }
            }

            .arrow-icon {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg > path {
                    fill: $color__default;
                }
            }
        }
    }

    .empty-view-container {
        width: 100%;
        height: 100%;
    }
}