.reservation_list_page_blank {
  width: 100%;
  height: 100%;
}
.reservation_list_page {
  background-color: $color__white;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .reservation_top {
    z-index: 1;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: $color__white;
    flex-shrink: 0;
    flex-grow: 0;

    & > .main_navi {
      padding: 0 20px;
      height: 56px;
      display: flex;
      align-items: center;

      & > .title {
        @include font__title_19_sb;
        margin-right: auto;

        &.open_store_list {
          display: flex;
          align-items: center;

          > i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
          }
        }

      }

      & .today-btn-wrap {
        display: flex;
        align-items: center;
        padding: 8px;

        & button.today {
          @include font__s_small_sb;
          padding: 0 6px;
          height: 24px;
          border: 1px solid $color__black;
          border-radius: var(--button-radius);
          background-color: $color__white;
          color: $color__black;
          cursor: pointer;
          &.active {
            background: var(--primary-color);
            color: $color__white;
            border: 1px solid var(--primary-color);
          }
        }
      }

      & .calendar-icon-wrap {
        display: flex;
        align-items: center;
        padding: 9px;
        margin-right: -9px;

        & > svg {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
      }
    }

    & > .schedule {
      padding: 0 20px;
      height: 67px;
      border-bottom: 1px solid $color__lgrey_96;
      background-color: $color__white;
      cursor: pointer;
    }

    & > .filter-menu {
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color__lgrey_96;
      background-color: $color__white;
      
      .time-div-wrap {
        overflow-y: hidden;
        
        .time-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          white-space: pre;
          column-gap: 24px;
          padding-inline: 20px;
          overflow-y: auto;
          & > label {
            display: flex;
            align-items: center;

            & > i {
              margin-right: 4px;
              transform: none;
            }
            & > input ~ span {
              @include font__small;
              color: $color__black;
            }
            & > input:not(:checked) ~ span {
              color: $color__grey_66;
            }
          }
        }
      }

      .filter-menu-wrap {
        flex-shrink: 0;
        padding: 8px 20px 8px 0px;
        position: relative;
        height: 100%;
        
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: calc(100% + 20px);
          height: 100%;
          background: linear-gradient(270deg, #FFFFFF 80.5%, rgba(255, 255, 255, 0) 100%);
        }

        button.toggle_filter {
          @include font__small_sb;
          position: relative;
          z-index: 0;
          padding: 0 8.5px;
          height: 32px;
          line-height: unset !important;
          display: inline-flex;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.08);
          border-radius: var(--button-radius);
          background-color: $color__white;
          color: $color__black;
          cursor: pointer;
  
          & > svg {
            flex-shrink: 0;
            margin-right: 4px;
            width: 14px;
            height: 14px;
          }
          &.isFiltered {
            color: $color__white;
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            & > svg > path {
              fill: $color__white;
            }
          }
        }
      }
    }
  }

  .reservation_list {
    padding: 24px 20px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $color__bg_98;
    flex: 1;

    & > .indicator {
      @include font__body_sm;
      margin-bottom: 16px;
      // width: 335px;
      width: 100%;
      color: $color__black;
      text-align: left;
    }
    & > .card {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    & > .empty-data-note {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include font(15px, $color__dgrey_38, 400, 22px);
      & > svg {
        margin-bottom: 16px;
      }
    }
  }

  & > .tblm-btn-float {
    position: fixed;
    right: 16px;
    bottom: 32px;
    padding: 16px;
    z-index: 99;

    &.tmAppPosition {
      bottom: calc(32px + 56px); // 하단 gnb만큼 올리기
    }
  }
}

button.bottom_modal__close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 28px;
  height: 28px;
  display: block;
  border: 0;
  border-radius: 14px;
  background-image: url('/assets/images/icons/Close_BoottoMenu.svg');
  background-size: 100%;
  cursor: pointer;
}

.send_cancel_alarm_wrap {
  display: flex;
  margin-top: 16px;
  label {
    display: flex;
    align-items: center;
    i {
      margin-right: 8px;
    }
  }
}

.receive-deposit-check-wrap {
  display: flex;
  margin-top: 0;
  align-items: center;
}

// 테매앱 매장 선택할 수 있는 모달
.select-store-list-modal-wrapper {
  max-height: 100%;
  overflow: hidden;

  .title {
    margin-bottom: 8px;
  }

  .store-list {
    height: fit-content;
    padding-inline: 20px;
    padding-bottom: 20px;
    padding-top: 16px;
    flex: 1;
    overflow: auto;

    li {
      &:not(&:first-of-type) {
        border-top: 1px solid $color__lgrey_93;
      }

      label {
        @include font__body_sm_m;
        display: flex;
        padding: 16px 4px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        cursor: pointer;
      }
    }
  }
}