@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.outside_tooltip {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.info_tooltip {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  line-height: 0;

  // icon
  &__icon {
    position: relative;
    left: 0;
    margin-left: 3px;
    vertical-align: sub;
  }

  .tooltip_message {
    position: absolute;
    right: calc(-100px + 8px);
    display: flex;
    gap: 8px;
    width: 200px;
    background-color: $color__default;
    padding: 16px;
    margin-top: 4px;
    box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: var(--textfield-radius);
    z-index: 6;

  p {
    @include font__s_small;
    color: $color__white;
    flex-grow: 1;
    flex-basis: 144px;
    white-space: pre-line;
  }

  &__close {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(0px - 8px);
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-bottom: 8px solid $color__default;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}

}
