@import '../../../styles/utils/variables';
@import '../../../styles/utils/boxshadow';
@import '../../../styles/utils/filters';
@import '../../../styles/utils/mixin';
@import '../../../styles/utils/responsive';
@import '../../../styles/utils/textstyle';

@import '../../../styles/presets/buttons';

.customer-detail-container {
    .main-container {
        &.emptyData {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 55px);

            .user-history-container {
                height: 100%;
                flex: 1;
                
                .empty-view-container {
                    height: calc(100% - 49px);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        @include font__body_sm;
                        color: $color__grey_66;
                    }
                }
            }
        }

        .user-info-container {
            background-color: $color__white;
            margin-bottom: 8px;
            padding-bottom: 16px;

            .user-default-data {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-inline: 20px;

                &.editableUserDefaultData {
                    flex-direction: column;
                    row-gap: 8px;
                }

                .user-default {
                    h2 {
                        @include font__title_20_sb;
                        padding-bottom: 8px;
                    }

                    h5 {
                        @include font__subtitle1_m;
                    }
                }
            }

            .user-contact-data {
                padding-block: 16px;
                border-bottom: 1px solid #F1F1F1;
                > ul {
                    display: flex;
                    align-items: center;
                    margin-inline: 20px;

                    li {
                        flex: 1;
                        text-align: center;
                        margin-block: 5px;

                        &:not(li:first-of-type) {
                            border-left: 1px solid #F1F1F1;
                        }

                        h3 {
                            @include font__title_19_sb;
                            margin-bottom: 8px;
                        }

                        span {
                            @include font__subtitle1_m;
                            color: $color__grey_66;
                        }
                    }
                }
            }

            .contact-button-container {
                margin-inline: 20px;
                > ul {
                    display: flex;
                    align-items: center;
                    min-height: 48px;
                    li {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 4px;
                        margin-block: 12px;

                        &:not(li:first-of-type) {
                            border-left: 1px solid #F1F1F1;
                        }

                        em {
                            @include font__subtitle1_sb;
                        }

                        .call-icon {
                            path {
                                stroke: $color__default;
                            }
                        }

                        .message-icon {
                            path:not(path:first-of-type) {
                                stroke: $color__default;
                            }

                            path:first-of-type {
                                fill: $color__default;
                            }
                        }
                    }
                }

                .submit-reservation-btn {
                    @include font__subtitle1_sb;
                    width: 100%;
                    display: block;
                    min-height: 40px;
                    border-radius: var(--button-radius);
                    border: 0;
                    color: $color__dgrey_38;
                    background-color: $color__lgrey_96;
                }
            }
        }

        .user-history-container {
            background-color: $color__white;

            .tabs-wrapper {
                top: 55px;
            }
        }
    }
}