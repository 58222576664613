@import '../../utils/variables';
@import '../../utils/boxshadow';
@import '../../utils/filters';
@import '../../utils/mixin';
@import '../../utils/responsive';
@import '../../utils/textstyle';

@import '../../presets/buttons';

.normal-informaion-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  section {
    position: relative;
    width: 100%;
    background-color: $color__white;
    height: calc(100% - 91px);
    padding: 56px 20px 0 20px;
    .reservation-nono {
      cursor: pointer;
      width: calc(100% - 40px);
      text-align: center;
      position: absolute;
      bottom: 16px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $color__dgrey_38;
      text-decoration: underline;
    }
  }
  .title {
    font-size: 32px;
    color: $color__black;
    line-height: 40px;
    letter-spacing: -0.5px;
    font-weight: 600;
    white-space: pre-line;
    margin-bottom: 24px;
  }
  footer {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 20px;
    bottom: 0px;
    border-top: solid $color__lgrey_93 1px;
    > button {
      width: 100%;
    }
    .right {
      margin-right: 8px;
    }
  }
  .arter-note {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    white-space: pre-line;
    color: $color__default;
    margin-bottom: 32px;
  }
}
