@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// TutorialDayTime styles
.tutorial-content-container-time {
  .tutorial-content {
    .tutorial-subquestion-head {
      width: 100%;
      & > .tutorial-subquestion-title {
        @include font__title_20;
        span {
          color: var(--primary-color);
        }
        margin-bottom: 24.5px;
        &.time {
          margin-bottom: 42px;
        }
      }
      .tblm-rc {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        & > i {
          margin-right: 8px;
          transform: none;
        }
        & > span {
          @include font__body_sm_sb;
          // width: 56px;
          display: block;
          text-align: right;
          letter-spacing: 1px;
        }
      }
    }
    .tutorial-subquestion-body {
      width: 100%;
      flex: 1;
      &.sameday {
        .tblm-rc {
          & > span {
            margin-right: auto;
            @include font__body_sm_m;
          }

          & span.acitve-day {
            color: var(--primary-color);
          }

          input[type='checkbox']:checked ~ span {
            font-weight: 600;
          }
          input[type='checkbox'][readonly] ~ i {
            opacity: 0;
          }
          input[type='checkbox']:disabled ~ span {
            color: $color__lgrey_89;
          }
        }
      }
    }
    &.time {
      .tutorial-subquestion-head {
        border-bottom: 1px solid $color__lgrey_93;
        & > .tutorial-subquestion-title {
          margin-bottom: 42px;
        }
      }
      .tutorial-subquestion-body {
        overflow: overlay;
        overflow: auto;
        & > ul {
          padding: 24px 0;
          margin: 0;
          width: 100%;
          height: 100%;
          & > li {
            display: flex;
            align-items: flex-start;
            width: 100%;
            min-height: 40px;
            &:not(:last-child) {
              margin-bottom: 24px;
            }
            & > label {
              display: flex;
              align-items: center;
              height: 40px;
              & > i {
                margin-right: 32px;
              }
              & > span {
                @include font__body_sm_sb;
                width: 40px;
                display: block;
                text-align: center;
              }
            }
            & > .times {
              user-select: none;
              margin-left: 12px;
              width: 100%;
              height: 100%;
              display: flex;
              gap: 8px;
              align-items: flex-start;
              flex-wrap: wrap;
              & > label {
                flex: 1;
                height: 40px;
                min-width: 25%;
                & > input {
                  display: none;
                }
                & > div {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: $color__black;
                  @include font__body_sm;
                  border-radius: var(--textfield-radius);
                  padding: 0px 8px;
                }
                & > input:not(:checked) + div {
                  border: 1px solid $color__lgrey_89;
                }
                & > input:checked + div {
                  background-color: $color__black;
                  color: $color__white;
                }
              }
            }
          }

        }
      }
    }
  }
  .button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    // margin: 20px -20px 0 -20px;
    width: 100%;
    max-width: 480px;
    background: $color__white;
    padding: 20px;
    border-top: 1px solid $color__lgrey_89;
    z-index: 6;
    & > button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      @include font__subtitle1_sb;

      &.prev {
        @include tblm-button-gray;
      }

      &.next {
        @include tblm-button-color(var(--primary-color-rgb));
        color: $color__white !important;
        padding: 0;
      }
    }
  }
}
