@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

// Card styles
.card {
  position: relative;
  background-color: $color__white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 20px;
  // width: 335px;
  overflow: hidden;
  width: 100%;
  // max-width: 375px;
  min-height: 162px;
  &::before {
    content: '';
    left: 20px;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-image: url(/assets/images/icons/Reserve_L.svg);
  }
  &.canceled::before {
    background-image: url(/assets/images/icons/ic_res_cancel.svg);
  }
  &.enter::before {
    background-image: url(/assets/images/icons/ic_res_enter.svg);
  }
  &.exit::before {
    background-image: url(/assets/images/icons/ic_res_exit.svg);
  }
  &.noShow::before {
    background-image: url(/assets/images/icons/ic_res_noshow.svg);
  }
  &.valid::before {
    background-image: url(/assets/images/icons/ic_res_reserv.svg);
  }
  .card__time {
    margin-top: 4px;
  }
  .card__time,
  .card__reservation_name,
  .card__essential,
  .card__assign,
  .card__extrainfo,
  .card__tag_container {
    margin-left: 52px !important;
  }
  div.card__time {
    @include font__small_sb;
    color: $color__black;
    margin-bottom: 8px;
  }
  div.card__reservation_name {
    @include font__body_md_sb;
    color: var(--primary-color);
    margin-bottom: 8px;
  }
  div.card__essential {
    @include font__body_md_sb;
    color: $color__black;
    margin-bottom: 2px;
    display: flex;
    align-items: center;

    & > a {
      line-height: 0;
    }

    span.card__essential_phone {
      @include font__body_md;
      margin-left: 4px;
      padding-right: 1px;
    }
    svg.call_icon {
      margin-left: 4px;

      &.tm_call_icon {
        // tablemanager app setting primary color
        rect, path {
          fill: var(--accent-color);
        }

        path {
          stroke: var(--accent-color);
        }
      }
    }

  }
  div.card__assign {
    @include font__body_md;
    color: $color__black;
    display: flex;
    flex-wrap: wrap;
    & > span {
      display: inline-block;
    }
    & > div.card__assign_headcount {
      display: inline-flex;
      & > span:not(:last-child) {
        display: flex;
        align-items: center;
        margin-right: 4px;
        &::after {
          content: '';
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: $color__grey_75;
          margin-left: 4px;
        }
      }
    }
    & > div.card__assign_table {
      & > span.card__assign_table_label {
        color: $color__grey_66;
      }
      & > span.card__assign_table_separator {
        color: $color__lgrey_89;
        position: relative;
        padding: 0 2px;
        margin: 0 2px;
        &::before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 13.5px;
          background-color: $color__lgrey_89;
        }
      }
      & > span.card__assign_table_value {
      }
    }
  }
  section.card__reservation_info_scroll {
    height: 100%;
    overflow: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;
    margin-inline: -20px;
    padding-inline: 20px;
  }
  ul.card__extrainfo {
    padding: 0;
    margin: 0;
    padding-top: 16px;
    display: none;
    & > li {
      display: flex;
      align-items: flex-start;
      color: $color__black;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      & > div.card__extrainfo_label {
        @include font__ss_small;
        width: 51px;
        background: $color__white;
        border: 1px solid $color__lgrey_89;
        border-radius: 2px;
        padding: 3px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        min-height: 20px;
        margin-right: 8px;
      }
      & > div.card__extrainfo_value {
        @include font__small;
        line-height: 20px;
      }
    }
  }
  div.card__tag_container {
    margin-top: 16px;
    overflow: hidden;
    margin-bottom: -8px;
    & > button {
      max-width: 100%;
      margin-bottom: 8px;
      margin-right: 4px;
      padding: 4px 8px;
      --fix: '#';
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  & > button.card__close {
    display: none;
  }
  & > .card__changestatus {
    display: none;
  }
  & > .card__separator {
    display: none;
  }
  & > .card__controls {
    display: none;
  }
  &.card__spreaded {
    ul.card__extrainfo {
      display: block;
    }
  }
  &:global(.card__active) {
    box-shadow: 0 0;
    // max-width: 375px !important;
    &.card__is_modal {
      padding-top: 0;

      display: flex;
      flex-direction: column;
    }
    .card__changestatus {
      margin-top: 20px;
      display: flex;
      width: 100%;
      padding: 12px;
      background-color: $color__lgrey_96;
      border-radius: var(--button-radius);
      column-gap: 8px;
      & > button {
        cursor: pointer;
        @include font__subtitle1_sb;
        height: 40px;
        flex: 1;
        background-color: $color__white;
        color: $color__grey_66;
        border: 1px solid $color__lgrey_89;
        border-radius: var(--button-radius);
        &.card__status_active {
          color: $color__black;
          border: 1px solid $color__black;
        }
      }
    }
    .card__separator {
      display: block;
      margin: 20px -20px;
      padding: 0;
      width: 480px;
      height: 1px;
      border: 0;
      background-color: $color__lgrey_93;
    }
    .card__controls {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      & > button {
        @include font__subtitle1_sb;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        border: 0;
        border-radius: var(--button-radius);
        background-color: $color__lgrey_96;
        color: $color__dgrey_38;
      }
      & > button.card__control_color {
        width: 48px;
        flex-shrink: 0;
        flex-grow: 0;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: var(
            --color,
            linear-gradient(
              157.83deg,
              #f20000 15.63%,
              #fbc91a 26.18%,
              #98fb1a 37.47%,
              rgba(26, 251, 89, 0.848958) 47.3%,
              rgba(26, 197, 251, 0.848958) 58.95%,
              rgba(87, 104, 255, 0.848958) 66.23%,
              rgba(211, 87, 255, 0.848958) 73.88%,
              rgba(255, 87, 127, 0.848958) 81.16%,
              rgba(255, 87, 97, 0.848958) 84.43%
            )
          );
        }
      }
      & > button.card__control_noshow {
        flex: 1;
      }
      & > button.card__control_cancel {
        flex: 1;
      }
      & > div.card__control_revealtime {
        flex: 1;
        color: #4f5861;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > button.card__control_detail {
        width: 100%;
        flex-basis: 100%;
        background-color: var(--primary-color);
        color: $color__white;
      }
      & > .card__color-selector {
        position: absolute;
        left: 0;
        height: 48px;
        width: 100%;
        padding: 0 24px;
        //background-color: rgba(0,0,0,0.2);
        background-color: $color__white;
        display: none;
        overflow: overlay;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }
        &:global(.showColorSelect) {
          display: flex;
        }
        & > button {
          flex-shrink: 0;
          flex-grow: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          border: 0;
          border-radius: var(--button-radius);
          width: 40px;
          background-color: transparent;
        }
        & > button.card__color-selector_color_none {
          border-radius: 8px;
          position: relative;
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            border: 1px solid $color__grey_66;
            background-color: $color__white;
          }
          &::after {
            content: '';
            position: absolute;
            height: 1px;
            left: 50%;
            top: 50%;
            width: 16px;
            background-color: $color__grey_66;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
        & > button.card__color-selector_color {
          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: var(
              --color,
              linear-gradient(
                157.83deg,
                #f20000 15.63%,
                #fbc91a 26.18%,
                #98fb1a 37.47%,
                rgba(26, 251, 89, 0.848958) 47.3%,
                rgba(26, 197, 251, 0.848958) 58.95%,
                rgba(87, 104, 255, 0.848958) 66.23%,
                rgba(211, 87, 255, 0.848958) 73.88%,
                rgba(255, 87, 127, 0.848958) 81.16%,
                rgba(255, 87, 97, 0.848958) 84.43%
              )
            );
          }
        }
      }
    }
    & > button.card__close {
      position: absolute;
      display: block;
      top: 12px;
      right: 12px;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background-image: url('/assets/images/icons/Close_BoottoMenu.svg');
      background-size: 100%;
      border: 0;
      cursor: pointer;
    }
  }
}
