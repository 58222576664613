@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/boxshadow';
@import '../../../../styles/utils/filters';
@import '../../../../styles/utils/mixin';
@import '../../../../styles/utils/responsive';
@import '../../../../styles/utils/textstyle';

@import '../../../../styles/presets/buttons';

.clear-cache-container {
    background-color: $color__white;

    // main
    .clear-cache-main {
        .desc-wrapper {
            padding-block: 24px;
            padding-inline: 20px;
            h3 {
                @include font__subtitle_sb;
                padding-bottom: 8px;
            }

            span {
                @include font__body_sm_m;
                color: $color__dgrey_38;
            }
        }

        .os-tab-wrapper {
            padding-inline: 20px;
            
            ul {
                background: $color__bg_98;
                border-radius: 24px;
                display: flex;
                align-items: center;
                column-gap: 16px;

                li {
                    flex: 1;
                    margin: 4px;
                    padding: 6px;
                    @include font__subtitle_sb;
                    color: $color__grey_75;
                    text-align: center;

                    &.active {
                        background: $color__white;
                        border-radius: 20px;
                        color: $color__default;
                    }
                }
            }
        }

        .how-to-clear-cache-wrapper {
            padding: 32px 20px;

            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }
        }
    }
}