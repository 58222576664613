@import '../../styles/utils/variables';
@import '../../styles/utils/boxshadow';
@import '../../styles/utils/filters';
@import '../../styles/utils/mixin';
@import '../../styles/utils/responsive';
@import '../../styles/utils/textstyle';

@import '../../styles/presets/buttons';

.picker-container {
  z-index: 6;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  width: 100%;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .picker-inner {
    position: relative;

    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 20px;

    font-size: 1.2em;
    -webkit-mask-box-image: linear-gradient(to top, transparent, transparent 5%, $color__white 20%, $color__white 80%, transparent 95%, transparent);
    gap: 16px;
  }

  .picker-column {
    flex: 1 1;

    position: relative;
    max-width: 80%;

    max-height: 100%;
    z-index: 1;
    overflow: hidden;
    text-align: center;

    .picker-scroller {
      outline: none;
      transition: 300ms;
      transition-timing-function: ease-out;
    }

    .picker-item {
      position: relative;

      padding: 0 10px;

      white-space: nowrap;
      overflow: hidden;
      font-weight: 600;
      font-size: 15px;
      font-style: normal;
      line-height: 18px;
      text-overflow: ellipsis;
      color: $color__grey_75;
      &.picker-item-selected {
        color: var(--primary-color);
        font-size: 20px;
        line-height: 28px;
        height: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .picker-highlight {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    background: $color__lgrey_89;
    pointer-events: none;
    height: 40px !important;
    opacity: 0.2;
    //&:before,
    //&:after {
    //  //content: ' ';
    //  position: absolute;
    //  left: 0;
    //  right: auto;
    //
    //  display: block;
    //  width: 100%;
    //  height: 1px;
    //
    //  background-color: #d9d9d9;
    //  transform: scaleY(0.5);
    //}
    //
    //&:before {
    //  top: 0;
    //  bottom: auto;
    //}
    //
    //&:after {
    //  bottom: 0;
    //  top: auto;
    //}
  }
}
